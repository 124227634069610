import ApiUrlQuery from './ApiUrlQuery';
import ApiError from '../error/ApiError';
import AccessError from '../error/AccessError';
import ValidationError from '../error/ValidationError';
import NotFoundError from "../error/NotFoundError";

export default class Api {
  constructor(uri, { allowedQueryKeys = [] } = {}) {
    this.allowedQueryKeys = allowedQueryKeys;
    this.query = {};
    this.uri = uri;
    this.reset();
  }

  reset() {
    this.query = {};
    return this;
  }

  appendQueryKey(name) {
    if (!this.allowedQueryKeys.includes(name)) {
      this.allowedQueryKeys.push(name);
    }

    return this;
  }

  setQuery(query) {
    this.reset();
    this.query = query;

    return this;
  }

  queryEntry(name, value) {
    this.query[name] = value;

    return this;
  }

  handleError(error) {
    if (!error.response || error instanceof ApiError) {
      throw error;
    }

    // TODO improve this hole thing
    if (error.response.data.error && error.response.data.error.name === 'ValidationError') {
      throw new ValidationError(error.response);
    }

    switch (error.response.status) {
      case 401:
      case 403:
        throw new AccessError(error.response);
      case 404:
        throw new NotFoundError(error.response);
      default:
        throw new ApiError(error.response);
    }
  }

  buildUri(uri = '') {
    if (!uri) {
      return this.uri;
    }

    if (!this.uri) {
      return uri;
    }

    return `${this.uri}/${uri}`;
  }

  buildQuery() {
    const query = new ApiUrlQuery();
    this.allowedQueryKeys.forEach((key) => {
      if (Object.hasOwnProperty.call(this.query, key)) {
        query.append(key, this.query[key]);
      }
    });

    return query;
  }
}
