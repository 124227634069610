<template>
  <div class="figure">
    <figure :class="figureClass">
      <picture v-if="imgBindings" :class="imgClass">
        <img v-bind="imgBindings" />
      </picture>
    </figure>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  file: {
    required: true,
  },
  outlined: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'full',
  }
});

const imgSize = computed(() => (props.outlined ? 'full' : props.size));
const imgClass = computed(() => ({
  'image': true,
  'outline': props.outlined,
}));
const figureClass = computed(() => ({
  'aligncenter': true,
  'is-resized': imgSize.value !== 'full',
}))

const imgBindings = computed(() => {
  if (!props.file || !props.file.data || !props.file.data.attributes) {
    return null;
  }

  const {alternativeText, formats} = props.file.data.attributes;
  const {url, height, width} = imgSize.value !== 'full' && formats && formats[imgSize.value] ? formats[imgSize.value] : props.file.data.attributes;

  return {
    height,
    width,
    src: `${process.env.VUE_APP_STRAPI_API_URL}${url}`,
    alt: alternativeText || undefined,
  }
});
</script>
