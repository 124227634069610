import { createI18n } from 'vue-i18n';
import de from './de';
import datetimeFormats from './datetimeFormats';

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  datetimeFormats,
  messages: {
    de,
  },
});

export function translateValidation(message) {
  const [key, value] = message.split('|');
  const transKey = `validation.${key}`;

  return i18n.global.te(transKey) ? i18n.global.t(transKey, { value }) : message;
}

export default i18n;
