<template>
  <div class="contact-info">
    <aside class="preview-contact">
      <div class="img">
        <picture class="image round aspect-ratio-1-1">
          <img v-bind="img" />
        </picture>
      </div>
      <h3 class="name" v-text="name" />
      <a v-if="email" :href="`mailto:${email}`" class="item email" v-text="email" />
      <a v-if="phone" :href="`tel:${phone}`" class="item phone" v-text="`Tel: ${phone}`" />
      <a v-if="fax" :href="`fax:${email}`" class="item fax" v-text="`Fax: ${fax}`" />
    </aside>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';

const props = defineProps({
  contact: {
    type: Object,
    required: true,
  },
});

const isValid = computed(() => {
  return props.contact && props.contact.data && props.contact.data.attributes;
});

function attribute(key) {
  return () => {
    if (!isValid.value) {
      return '';
    }

    return props.contact.data.attributes[key];
  }
}

const name = computed(attribute('name'));
const email = computed(attribute('email'));
const phone = computed(attribute('phone'));
const fax = computed(attribute('fax'));

const img = computed(() => {
  if (!isValid.value || !props.contact.data.attributes?.image.data.attributes) {
    return null;
  }

  const {url, alternativeText} = props.contact.data.attributes.image.data.attributes;

  return {
    src: `${process.env.VUE_APP_STRAPI_API_URL}${url}`,
    alt: alternativeText || undefined,
  }
});

</script>
