<template>
  <div class="quote" :class="quoteClasses">
    <figure v-if="hasImage" class="figure">
      <picture class="image aspect-ratio-1-1" :class="outlineClass">
        <img v-bind="imageAttrs" />
      </picture>
      <figcaption v-if="name" class="caption">
        <span class="name" v-text="name" />
        <span v-if="name_text" class="text" v-text="name_text" />
      </figcaption>
    </figure>

    <blockquote v-text="text" />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  file: Object,
  name: String,
  name_text: String,
  position: String,
});

const hasImage = computed(() => props.file && props.file.data && props.file.data.attributes);
const imageAttrs = computed(() => {
  if (!hasImage.value) {
    return {};
  }

  const {url, alternativeText} = props.file.data.attributes;

  return {
    src: `${process.env.VUE_APP_STRAPI_API_URL}${url}`,
    alt: alternativeText || undefined,
  }
})

const quoteClasses = computed(() => {
  const classes = [];

  if (hasImage.value) {
    if(props.position !== 'right') {
      classes.push('quote-left');
    }

    classes.push('cols', 'cols-2');
  }

  return classes;
});

const outlineClass = computed(() => {
  return props.position !== 'right' ? ['outline', 'with-margin'] : ['outline', 'outline-left', 'with-margin'];
})
</script>
