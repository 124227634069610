<template>
  <div class="item">
    <button class="control" type="button" aria-expanded="false" :aria-controls="`accordion-item-${id}`" v-text="title" @click.prevent.stop="onItemClick" />
    <div class="content" :id="`accordion-item-${id}`" v-html="content" />
  </div>

</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {stripEmptyTags} from "@/components/blocks/helper/content";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  }
});

const content = ref(stripEmptyTags(props.text));
watch(() => props.text, (value) => {
  content.value = stripEmptyTags(value);
});

function onItemClick(event) {
  const expanded = event.target.getAttribute('aria-expanded') === 'true';
  event.target.setAttribute('aria-expanded', expanded ? 'false' : 'true');
}
</script>
