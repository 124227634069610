<template>
  <form class="form" @submit.prevent.stop="onSubmit">
    <!-- TODO add key icon here -->
    <BaseInput name="password" type="password" :required="true" />
    <button
      type="submit"
      class="btn btn-square btn-lg bg-yellow"
      v-text="$t(`${inputLabelTranslation}.submit`)"
    />
  </form>
</template>

<script>
import { reactive, ref, readonly } from 'vue';
import { useRoute } from 'vue-router';
import currentUser from '@/stores/CurrentUser';
import BaseInput from '@/components/input/Input';
import ValidationError from '@/api/error/ValidationError';

export default {
  name: 'LoginPage',
  components: { BaseInput },

  setup() {
    const route = useRoute();
    const context =ref( route.name === 'backend.login' ? 'backend.login' : 'login');

    return {
      context,
      validations: reactive({}),
      model: reactive({}),
      inputLabelTranslation: readonly(context),
    };
  },

  methods: {
    async onSubmit() {
      try {
        delete this.validations.password;
        if (this.context === 'backend.login') {
          await currentUser.loginBackend(this.model.password);
          await this.$router.push({name: 'backend.home'});
        } else {
          await currentUser.loginFrontend(this.model.password);
          await this.$router.push({name: 'home'});
        }
      } catch (error) {
        if (error instanceof ValidationError) {
          this.validations.password = [this.$t(`${this.inputLabelTranslation}.incorrect_password`)];
          return;
        }

        throw error;
      }
    },
  },
};
</script>
