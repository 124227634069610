<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <SelectInput name="gender" :options="genders" :required="true" default-text="" />
    <BaseInput name="firstname" :required="true" />
    <BaseInput name="lastname" :required="true" />
    <BaseInput name="email" :required="true" />
    <BaseInput name="phone" :required="true" />
    <SelectInput
      name="company"
      :options="selectCompanies"
      default-text=""
      cast="int"
      :required="true"
    />

    <div class="alert info" v-if="costInformation">
      <strong v-text="`${$t(`${inputLabelTranslation}.cost_info_company_headline`)}: `" />
      <span v-text="costInformation" />
    </div>
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsFieldset from '@/mixins/input/IsFieldset';
import SelectInput from '@/components/input/Select';
import BaseInput from '@/components/input/Input';

export default {
  name: 'PersonInfo',
  components: { BaseInput, SelectInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'person_info',
      type: String,
    },
  },

  setup() {
    return {
      genders: registerStore.selectGenders(),
      selectCompanies: registerStore.selectCompanies(),
      info: registerStore.info,
    };
  },

  computed: {
    costInformation() {
      return this.currentValue.company
        ? this.info.companyCosts[this.currentValue.company] || null
        : null;
    },
  },
};
</script>
