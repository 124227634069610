<template>
  <article v-if="!loading" class="article">
    <header class="header">
      <h1 class="headline" v-text="page.title" />
      <span v-if="page.subtitle" class="subheadline" v-text="page.subtitle" />
    </header>

    <component
      v-for="(block, index) in page.content"
      :key="`content-block-${index}`"
      :is="block.component"
      v-bind="block.data"
    />

    <ContactInfo v-if="page.contact" :contact="page.contact" />
  </article>
</template>

<script setup>
import { reactive, ref, onBeforeMount, onBeforeUpdate } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContactInfo from '@/components/ContactInfo';
import pageStore from '@/stores/Pages';
import NotFoundError from '@/api/error/NotFoundError';

const loading = ref(false);
const page = reactive({});
const route = useRoute();
const router = useRouter();
const currentRoutPath = ref('');

function updatePage() {
  if (currentRoutPath.value === route.fullPath || loading.value) {
    return;
  }

  loading.value = true;
  const currentPath = route.fullPath;

  pageStore
    .fromSlug(currentPath)
    .then((response) => {
      Object.assign(page, response);
      loading.value = false;
      currentRoutPath.value = currentPath;
    })
    .catch((error) => {
      if (error instanceof NotFoundError) {
        router.push({
          name: 'not-found',
          // preserve current path and remove the first char to avoid the target URL starting with `//`
          params: { pathMatch: route.path.substring(1).split('/') },
          // preserve existing query and hash if any
          query: route.query,
          hash: route.hash,
        });
        return;
      }

      throw error;
    });
}

onBeforeMount(() => {
  updatePage();
});
onBeforeUpdate(() => {
  updatePage();
});
</script>
