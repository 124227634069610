<template>
  <div class="text" v-html="content" />
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {stripEmptyTags} from "@/components/blocks/helper/content";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const content = ref(stripEmptyTags(props.text));
watch(() => props.text, (value) => {
  content.value = stripEmptyTags(value);
});
</script>
