// file size in MB
export const MAX_FILE_UPLOAD_SIZE = process.env.VUE_APP_MAX_FILE_UPLOAD_SIZE;

export function totalFileSize(files) {
  let totalFileSize = 0;
  (Array.isArray(files) ? files : []).forEach((file) => {
    totalFileSize += file.size;
  });

  return totalFileSize;
}

function bytesToMegaBytes(bytes) {
  return bytes / 1024 ** 2;
}

export function maxFileSizeReached(files) {
  const totalSize = totalFileSize(files);
  const totalSizeMegaByte = bytesToMegaBytes(totalSize);
  return totalSizeMegaByte > MAX_FILE_UPLOAD_SIZE;
}

export default {
  totalFileSize,
  maxFileSizeReached
}
