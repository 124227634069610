import StrapiSingle from '@/api/base/StrapiSingle';
import { pageContactPopulate, pageContentPopulate } from '@/api/page';

export class SinglePage extends StrapiSingle {
  constructor() {
    super('');

    this._withContent = false;
    this._withContact = false;
  }

  async get(route) {
    if (this._withContent || this._withContact) {
      const populate = this.query['populate'] || [];
      if (this._withContent) {
        populate.push(...pageContentPopulate);
      }

      if (this._withContact) {
        populate.push(...pageContactPopulate);
      }

      this.query['populate'] = populate;
    }

    this.uri = route;
    return await super.get();
  }

  withContact(withContact = true) {
    this._withContact = withContact;

    return this;
  }

  withContent(withContent = true) {
    this._withContent = withContent;

    return this;
  }

  reset() {
    this.uri = '';
    this._withContent = false;
    this._withContact = false;
    return super.reset();
  }
}

const singlePage = new SinglePage();
export default singlePage;
