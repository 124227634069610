import StrapiResource from "@/api/base/StrapiResource";
import NotFoundError from "@/api/error/NotFoundError";

export const pageContentPopulate = ['content', 'content.file', 'content.items', 'content.items.file'];
export const pageContactPopulate = ['contact', 'contact.image'];
export const pagePopulate = [...pageContentPopulate, ...pageContactPopulate];

class Page extends StrapiResource {
  constructor() {
    super('pages');
  }

  async findBySlug(slug) {
    try {
      const response = await this
        .setQuery({
          populate: pagePopulate,
          filters: {
            slug: {$eq: slug},
          },
        })
        .list();

      if (!response.data || !Array.isArray(response.data) || response.data.length !== 1) {
        throw new NotFoundError(response);
      }

      return response.data[0];
    } catch (e) {
      this.handleError(e);
      return undefined;
    }
  }
}

const page = new Page();

export default page;
