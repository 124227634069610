import {nextTick, reactive, ref} from "vue";
import register from "@/stores/Register";

/**
 *
 * @param {RegistrationList} store
 * @returns {{filter: UnwrapNestedRefs<{}>, onSubmit: ((function(): Promise<void>)|*), onStatusChange: ((function(*, *): Promise<void>)|*), onPageChange: ((function(*): Promise<void>)|*), onReset: ((function(): Promise<void>)|*), currentPage: Ref<UnwrapRef<number>>}}
 */
function useRegistrationListPage(store) {
  const filter = reactive({});
  const currentPage = ref(1);

  async function onSubmit() {
    await update();
  }

  async function onReset() {
    Object.keys(filter).forEach((filterKey) => delete filter[filterKey]);
    await update();
  }

  async function update() {
    try {
      await store.find({
        filter: {...filter},
        pagination: {page: currentPage.value},
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function onPageChange(page) {
    currentPage.value = page;
    await update();
  }

  async function onStatusChange(item, newStatus) {
    const currentStatus = item.status;
    const reset = () => {
      if (!currentStatus) {
        return;
      }

      nextTick(() => {
        item.status = currentStatus;
      });
    };

    if (!newStatus || currentStatus === newStatus) {
      await nextTick(() => {
        reset();
      });
      return;
    }

    const changed = await store.changeStatus(item.hash, newStatus);
    if (!changed) {
      reset();
    }
  }

  async function onExport() {
    try {
      const csv = await store.csvString({
        filter: {...filter},
      });
      const today = new Date();
      const day = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      const filename = `${store.csvFilenamePrefix()}_${day}.csv`;

      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // check HTML5 download support
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.log(e);
    }
  }

  register.load();
  update();

  return {
    filter,
    currentPage,
    onSubmit,
    onReset,
    onPageChange,
    onStatusChange,
    onExport,
  }
}

export default useRegistrationListPage;
