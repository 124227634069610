import currentUser from '@/stores/CurrentUser';

const NO_AUTH_ROUTS = ['data-protection'];

export default async function (to, from, next) {
  const isAuthorized = (await currentUser.tryAuthenticate()) && currentUser.isLoggedIn();
  const isBackendContext = to.name && to.name.substring(0, 8) === 'backend.';

  const checkContext = (loginRouteName, homeRouteName, isAuthorized) => {
    const isLoginRout = to.name === loginRouteName;

    // when trying to access login page and not authorized, do it
    if (isLoginRout && !isAuthorized) {
      return next();
    }

    // continue if route can be access without authentication
    if (to.name && NO_AUTH_ROUTS.includes(to.name)) {
      return next();
    }

    // if logged in go to next
    if (isAuthorized) {
      // check if there is redirect saved and us this as next
      if (from.query.redirect && to.path !== from.query.redirect) {
        return next({ path: from.query.redirect });
      }

      // if trying to access login redirect to home
      if (isLoginRout) {
        return next({ name: homeRouteName });
      }

      return next();
    }

    return next({ name: loginRouteName, query: { redirect: to.path } });
  };

  return isBackendContext
    ? checkContext('backend.login', 'backend.home', isAuthorized && currentUser.isAdmin())
    : checkContext('login', 'home', isAuthorized);
}
