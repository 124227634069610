<template>
  <template v-if="loaded && model.cancellation_accept === false">
    <h2 v-text="cancellationInfo.headline" />
    <div class="content" v-html="cancellationInfo.text" />

    <div class="alert warning" v-if="waitingList.active">
      <div class="content" v-html="waitingList.text" />
    </div>

    <div class="btn-group">
      <button
        type="button"
        class="btn btn-square bg-yellow"
        @click.stop.prevent="onCancellationAccept"
        v-text="cancellationInfo.accept"
      />
    </div>
  </template>
  <form v-else-if="loaded && selectingType" class="form" @submit.stop.prevent="onTypeSelected">
    <RadiobuttonsInput
      name="type"
      :list="registrationTypes"
      :required="true"
      :has-legend-label="true"
      v-model="type"
    />

    <div class="btn-group">
      <button
        type="submit"
        class="btn btn-square btn-lg bg-yellow"
        v-text="$t('register.type_selected_btn')"
        :disabled="!type"
      />
    </div>
  </form>
  <form v-else-if="loaded && !created" class="form" @submit.stop.prevent="onSubmit">
    <component v-if="type" :is="`register-${type}`" />

    <div class="btn-group">
      <button
        type="submit"
        class="btn btn-square btn-lg bg-yellow"
        v-text="$t('register.submit')"
      />
    </div>
  </form>
  <FormAlert v-else-if="created" :alert="alert">
    <template #buttons>
      <router-link :to="{ name: 'home' }" class="btn btn-square bg-alert"
        >Zur Startseite</router-link
      >
    </template>
  </FormAlert>
</template>

<script>
import { reactive, readonly, ref } from 'vue';
import handlesValidations from '@/mixins/validations';
import RadiobuttonsInput from '@/components/input/Radiobuttons';
import registerStore from '@/stores/Register';
import { registrationTypes } from '@/defaults';
import FormAlert from '@/components/form/FormAlert';

export default {
  name: 'RegisterCreate',
  components: { FormAlert, RadiobuttonsInput },

  setup() {
    registerStore.load();

    return {
      selectingType: ref(true),
      type: ref(''),
      loaded: registerStore.loaded,
      created: ref(false),
      cancellationInfo: registerStore.cancellationInfo,
      waitingList: registerStore.waitingList,
      model: reactive({
        cancellation_accept: false,
      }),
      registrationTypes,
      inputLabelTranslation: readonly(ref('register')),
      ...handlesValidations,
    };
  },

  computed: {
    alert() {
      return this.type ? registerStore.success.created[this.type] : null;
    },
  },

  methods: {
    onCancellationAccept() {
      this.model.cancellation_accept = true;
    },

    async onSubmit() {
      this.handleValidationClear();
      try {
        await registerStore.createEntry(this.type, { ...this.model });
        this.created = true;
      } catch (error) {
        if (this.handleValidationError(error)) {
          return;
        }

        // TODO improve handling
        throw error;
      }
    },

    onTypeSelected() {
      if (this.type) {
        this.selectingType = false;
      }
    },
  },
};
</script>
