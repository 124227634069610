<template>
  <InputField v-bind="inputFieldProps">
    <input
      type="number"
      :value="currentValue"
      :id="inputId"
      :disabled="disabled"
      :class="['input', `-number`]"
      v-bind="props"
      placeholder=" "
      @input="updateInputValue($event.target.value)"
    />
  </InputField>
</template>

<script>
import IsInputField from '../../mixins/input/IsInputField';
import ModelInputField from '../../mixins/input/ModelInputField';

const createProps = ['step', 'min', 'max'];

export default {
  name: 'NumberInput',

  mixins: [IsInputField],

  props: {
    cast: {
      default: undefined,
      type: String,
    },
    step: {
      default: null,
      type: Number,
    },
    min: {
      default: null,
      type: Number,
    },
    max: {
      default: null,
      type: Number,
    },
  },

  computed: {
    props() {
      const props = {};

      createProps.forEach((key) => {
        if (this[key] !== null) {
          props[key] = this[key];
        }
      });

      return props;
    },
  },

  methods: {
    updateInputValue(value, name = null) {
      ModelInputField.methods.updateInputValue.call(this, this.castValue(value), name);
    },

    castValue(value) {
      let newVal = value;

      switch (this.cast) {
        case 'int':
          newVal = parseInt(value);
          break;
        case 'float':
          newVal = parseFloat(value);
      }

      if (isNaN(newVal)) {
        newVal = null;
      }

      return newVal;
    },
  },
};
</script>
