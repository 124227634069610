import IsGroup from './IsGroup';

export default {
  mixins: [IsGroup],

  props: {
    name: {
      type: String,
      default: '',
    },
    nameTrans: {
      type: String,
    },
    labelTrans: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
  },

  computed: {
    inputLabelTranslation() {
      const name = this.nameTrans ? this.nameTrans : this.name;

      if (!name) {
        return typeof this.$parent.inputLabelTranslation === 'string'
          ? this.$parent.inputLabelTranslation
          : '';
      }

      return typeof this.$parent.inputLabelTranslation === 'string'
        ? `${this.$parent.inputLabelTranslation}.${name}`
        : name;
    },

    groupLegendTranslationKey() {
      if (this.labelTrans) {
        return `${this.labelTrans}_legend`;
      }

      return this.name ? `legend_${this.name}` : 'legend';
    },

    groupLegend() {
      if (this.label) {
        return this.label;
      }

      if (typeof this.inputLabelTranslation === 'string') {
        const translationKey = `${this.inputLabelTranslation}.${this.groupLegendTranslationKey}`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },

    groupDescription() {
      if (this.description) {
        return this.description;
      }

      if (typeof this.inputLabelTranslation === 'string') {
        const translationKey = `${this.inputLabelTranslation}.${this.groupLegendTranslationKey.replace('_legend', '')}_description`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },

    groupClass() {
      return [
        'fieldset',
        this.nameTrans ? `-${this.buildHtmlId(this.nameTrans)}` : '', // TODO dose not take labelTrans as fallback
        `-${this.buildHtmlId(this.completeName)}`, // TODO seams not to work
      ];
    },
  },
};
