import StrapiResource from "@/api/base/StrapiResource";

export default class RegistrationResource extends StrapiResource {

  async changeStatus(id, status) {
    try {
      return (await window.axios.post(this.buildUri(`status-change/${id}`), {  status })).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }
}
