<template>
  <div class="container -contains-slide" style="position: relative; overflow: hidden">
    <div class="slider images" data-slider="images" ref="slider">
      <div v-for="(item, index) in items.data" :key="`slide-item-${index}`" class="slide">
        <picture class="image aspect-ratio-16-9">
          <img :alt="item.attributes.alternativeText" :src="buildSrc(item.attributes.url)" />
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue';
import initSlider from '@/assets/core/js/slider/images';
import { defaultGlideSettings } from '@/assets/core/js/slider';

// TODO may improve picture element generation by using img width + height and source tag

defineProps({
  items: {
    type: Object,
    required: true,
  },
});

const slider = ref({});
onMounted(() => {
  import('@glidejs/glide')
    .then((module) => module.default)
    .then((Glide) => initSlider(slider.value, Glide, defaultGlideSettings));
});

function buildSrc(uri) {
  return `${process.env.VUE_APP_STRAPI_API_URL}${uri}`;
}
</script>
