import { createWebHistory, createRouter } from 'vue-router';

import authCheck from './auth';

import HomePage from '@/views/HomePage';
import LoginPage from '@/views/LoginPage';
import RegisterCreate from '@/views/RegisterCreate';
import RegisterEdit from '@/views/RegisterEdit';
import SinglePage from '@/views/SinglePage';
import DataProtection from '@/views/DataProtection';
import NotFound from '@/views/NotFound';

import BackendHomePage from '@/views/backend/HomePage';
import BackendGraduatePage from '@/views/backend/GraduatePage';
import BackendEmployeePage from '@/views/backend/EmployeePage';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/anmeldung',
    name: 'register',
    component: RegisterCreate,
  },
  {
    path: '/anmeldung/ausbildung/:hash',
    name: 'register.edit.graduate',
    component: RegisterEdit,
  },
  {
    path: '/anmeldung/mitarbeitende/:hash',
    name: 'register.edit.employee',
    component: RegisterEdit,
  },
  {
    path: '/datenschutz',
    name: 'data-protection',
    component: DataProtection,
  },
  {
    path: '/backend',
    name: 'backend.home',
    component: BackendHomePage,
  },
  {
    path: '/backend/graduate',
    name: 'backend.graduate',
    component: BackendGraduatePage,
  },
  {
    path: '/backend/employee',
    name: 'backend.employee',
    component: BackendEmployeePage,
  },
  {
    path: '/backend/login',
    name: 'backend.login',
    component: LoginPage,
  },
  {
    path: '/:path(.*)',
    name: 'page',
    component: SinglePage,
    props: true,
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
  routes,
});

router.beforeEach(authCheck);

export default router;
