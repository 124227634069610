<template>
  <div class="timeline">
    <i class="dot" aria-hidden="true"></i>

    <component v-for="(component, index) in items" :is="component" :key="`timeline-item-${index}`" />

    <i class="dot" aria-hidden="true"></i>
  </div>
</template>

<script setup>
import { defineProps, h, reactive } from 'vue';
import svgLoader from "@/stores/SvgLoader";

const props = defineProps({
  items: {
    required: true,
    type: Array,
  },
});

function renderText(title, text) {
  const render = [];

  if (title) {
    render.push(h('strong', title));
  }

  if (text) {
    render.push(h('span', {innerHTML: text}));
  }

  return render;
}

function renderImage(file) {
  const format = file.formats && file.formats.small ? file.formats.small : file;

  return h('picture', {class: 'image aspect-ratio-1-1'}, h('img', {
    alt: file.alternativeText,
    src: `${process.env.VUE_APP_STRAPI_API_URL}${format.url}`,
    width: format.width,
    height: format.height,
  }));
}

const items = [];
props.items.forEach((item) => {
  const hasFile = !!(item.file && item.file.data && item.file.data.attributes);
  if (!hasFile && (!item.text && !item.title)) {
    return;
  }

  const props = reactive({class: ['item']});
  let render = undefined;
  if (hasFile) {
    const file = item.file.data.attributes;

    if (file.mime === 'image/svg+xml') {
      props.innerHTML = '';
      svgLoader.content(`${process.env.VUE_APP_STRAPI_API_URL}${file.url}`)
        .then(content => {
          props.innerHTML = content
        });
      props.class.push('-symbol');
    } else {
      render = renderImage(file);
      props.class.push('-img');
    }
  } else {
    render = renderText(item.title, item.text);
    props.class.push('-text');
  }

  items.push({
    functional: true,
    render: () => h('div', props, render),
  });
});
</script>
