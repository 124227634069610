import api from '@/api/registrationEmployee';
import {RegistrationList} from "@/stores/helper/RegistrationList";
import {populate as populateCsv, listEntry, csvEntry, csvHeaders} from '@/stores/helper/registrationEmployee';

const populate = [];

function createFilters(filterData) {
  const filters = [];

  if (filterData.name) {
    filters.push({
      $or: [
        {firstname: {$containsi: filterData.name}},
        {lastname: {$containsi: filterData.name}},
        {company: {name: {$containsi: filterData.name}}},
      ],
    });
  }

  if (filterData.status) {
    filters.push({status: {$eq: filterData.status}});
  }

  return {
    $and: filters,
  };
}

export class RegistrationEmployee extends RegistrationList {
  constructor() {
    super({
      createFilters,
      createEntry: listEntry,
      api,
      populate,
      csv: {
        populate: populateCsv,
        headerRowEntry: csvHeaders,
        createEntry: csvEntry,
        filenamePrefix: 'gala-mitarbeiter'
      }
    });
  }
}

const registrationEmployee = new RegistrationEmployee();
export default registrationEmployee;
