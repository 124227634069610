<template>
  <div>
    <slot />

    <SelectInput name="gender" :options="genders" :required="true" default-text="" />
    <BaseInput name="firstname" :required="true" />
    <BaseInput name="lastname" :required="true" />

    <ToggleSwitch name="address_different" />
    <template v-if="currentValue.address_different">
      <BaseInput name="market" :required="true" />
      <AddressInput name="address" />

      <ToggleSwitch name="billing_address_different" />
      <AddressInput name="billing_address" v-if="currentValue.billing_address_different" />
    </template>

    <OvernightStay />
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsRepeatItem from '@/mixins/input/IsRepeatItem';
import SelectInput from '@/components/input/Select';
import BaseInput from '@/components/input/Input';
import ToggleSwitch from '@/components/input/ToggleSwitch';
import AddressInput from '@/components/input/group/Address';
import OvernightStay from "@/components/form/employee/OvernightStay";

export default {
  name: 'AdditionalEmployeeRepeatItem',
  components: {
    OvernightStay,
    AddressInput,
    ToggleSwitch,
    BaseInput,
    SelectInput,
  },
  mixins: [IsRepeatItem],

  setup() {
    return {
      genders: registerStore.selectGenders(),
    };
  },
};
</script>
