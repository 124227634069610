export default {
  computed: {
    inputLabelTranslationKey() {
      return typeof this.$parent.inputLabelTranslation === 'string'
        ? `${this.$parent.inputLabelTranslation}.${this.name.replace(/_id$/, '')}`
        : null;
    },

    inputLabel() {
      return this.createInputLabelTranslation('label', '');
    },

    inputDescription() {
      return this.createInputLabelTranslation('description', '_description');
    },

    inputHint() {
      return this.createInputLabelTranslation('hint', '_hint');
    },
  },

  methods: {
    createInputLabelTranslation(propKey, transKeyAppender = '') {
      if (this[propKey]) {
        return this[propKey];
      }

      if (this.inputLabelTranslationKey) {
        const translationKey = `${this.inputLabelTranslationKey}${transKeyAppender}`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },
  },
};
