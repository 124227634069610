<template>
  <form class="form filter" @submit.stop.prevent="onSubmit">
    <BaseInput v-model="filter.name" name="name" :label="$t(`${filterTranslation}.name`)"/>

    <SelectInput
      v-model="filter.status"
      name="status"
      :label="$t(`${filterTranslation}.status`)"
      :options="statusTypes"
      default-text=""
    />

    <div class="btn-group">
      <button
        type="reset"
        class="btn btn-square bg-yellow no-arrow"
        v-text="$t(`${filterTranslation}.btn_reset`)"
        @click.stop.prevent="onReset"
      />

      <button
        type="submit"
        class="btn btn-square bg-yellow"
        v-text="$t(`${filterTranslation}.btn_submit`)"
      />
    </div>
  </form>

  <div class="table-wrap">
    <table class="table">
      <thead>
      <tr>
        <th v-text="$t(`${translation}.firstname`)"/>
        <th v-text="$t(`${translation}.lastname`)"/>
        <th v-text="$t(`${translation}.email`)"/>
        <th v-text="$t(`${translation}.status`)"/>
        <th/>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in store.entries" :key="index">
        <td v-text="item.firstname"/>
        <td v-text="item.lastname"/>
        <td v-text="item.email"/>
        <td>
          <SelectInput
            v-model="item.status"
            :name="`status-change-${index}`"
            :options="statusTypes"
            @input="(value) => onStatusChange(item, value)"
            default-text=""
          />
        </td>
        <td>
          <div class="btn-group">
            <router-link
              class="btn btn-round bg-yellow icon-after -external-link"
              :to="{ name: 'register.edit.employee', params: { hash: item.hash } }"
              v-text="$t(`${translation}.btn_edit`)"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <NumberPagination
    :page="store.meta.page"
    :count="store.meta.pageCount"
    @change="onPageChange"
  />

  <div class="btn-group" style="margin-top: 2rem">
    <button
      style="margin-left: auto"
      class="btn btn-square bg-yellow no-arrow"
      v-text="$t(`${filterTranslation}.btn_export`)"
      @click.stop.prevent="onExport"
    />
  </div>
</template>

<script setup>
import {readonly, ref} from "vue";
import {statusTypes} from "@/defaults";
import store from "@/stores/RegistrationEmployee";
import useRegistrationListPage from "@/mixins/registrationListPage";
import BaseInput from "@/components/input/Input.vue";
import SelectInput from "@/components/input/Select.vue";
import NumberPagination from "@/components/nav/NumberPagination.vue";

const translation = readonly(ref('backend.employee'));
const filterTranslation = readonly(ref(`${translation.value}.filter`));

const {filter, onSubmit, onReset, onPageChange, onStatusChange, onExport} = useRegistrationListPage(store);
</script>
