<template>
  <InputField v-bind="inputFieldProps" @dragover.prevent @drop.prevent @drop="dragFile" class="-upload">
    <div class="files" v-if="files">
      <div v-for="(file, index) in files" :key="`file-${index}`" class="item">
        <span v-text="file.name" />
        <button
          type="button"
          class="btn btn-square bg-gray no-arrow"
          @click.prevent.stop="removeFile(index)"
          v-text="'-'"
        />
      </div>
    </div>

    <div class="btn btn-square btn-lg">
      <span class="text" v-text="inputBtnText" />
      <input
        type="file"
        :id="inputId"
        :disabled="disabled"
        class="input -file"
        placeholder=" "
        @input="changeInput"
        multiple
      />
    </div>
  </InputField>
</template>

<script>
import IsInputField from '@/mixins/input/IsInputField';

export default {
  name: 'FileUpload',

  mixins: [IsInputField],

  data() {
    return {
      files: [],
    };
  },

  mounted() {
    if (this.model.uploads && !this.currentValue) {
      this.changeFiles(this.model.uploads);
    }
  },

  computed: {
    inputBtnText() {
      return this.createInputLabelTranslation('btnText', '_btn');
    },
  },

  methods: {
    dragFile($event) {
      this.changeFiles($event.dataTransfer.files);
    },

    changeInput($event) {
      this.changeFiles($event.target.files);
    },

    changeFiles(files) {
      [...(files || [])].forEach((file) => {
        this.files.push(file);
      });
      this.updateInputValue(this.files);
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
