import './bootstrap';

import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import i18n from '@/i18n';

import RegisterGraduate from '@/components/form/RegisterGraduate';
import RegisterEmployee from '@/components/form/RegisterEmployee';
// import RegisterCompany from '@/components/form/RegisterCompany';
import PersonRepeatItem from '@/components/input/group/PersonRepeatItem';
import AdditionalEmployeeRepeatItem from '@/components/input/group/AdditionalEmployeeRepeatItem';
import HeadlineBlock from '@/components/blocks/HeadlineBlock';
import TextBlock from '@/components/blocks/TextBlock';
import ImageBlock from '@/components/blocks/ImageBlock';
import VideoBlock from '@/components/blocks/VideoBlock';
import ButtonGroup from '@/components/blocks/ButtonGroup';
import AccordionBlock from '@/components/blocks/AccordionBlock';
import QuoteBlock from '@/components/blocks/QuoteBlock';
import HorizontalLine from '@/components/blocks/HorizontalLine';
import TimelineBlock from '@/components/blocks/TimelineBlock';
import SliderBlock from '@/components/blocks/SliderBlock';

createApp(App)
  .use(router)
  .use(i18n)
  .component('register-graduate', RegisterGraduate)
  .component('register-employee', RegisterEmployee)
  // .component('register-company', RegisterCompany)
  .component('PersonRepeatItem', PersonRepeatItem)
  .component('AdditionalEmployeeRepeatItem', AdditionalEmployeeRepeatItem)
  .component('block.headline', HeadlineBlock)
  .component('block.text', TextBlock)
  .component('block.image', ImageBlock)
  .component('block.video', VideoBlock)
  .component('block.button-group', ButtonGroup)
  .component('block.accordion', AccordionBlock)
  .component('block.quote', QuoteBlock)
  .component('block.horizontal-line', HorizontalLine)
  .component('block.timeline', TimelineBlock)
  .component('block.slider', SliderBlock)
  .mount('[data-vue="app"]');
