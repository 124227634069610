import i18n from '@/i18n';

export function typesToObject(optionList) {
  const obj = {};
  optionList.forEach((entry) => obj[entry.value] = entry.label);
  return obj;
}

export const registrationTypes = [
  { value: 'graduate', label: i18n.global.t('register.type_list.graduate') },
  { value: 'employee', label: i18n.global.t('register.type_list.employee') },
  // { value: 'company', label: i18n.global.t('register.type_list.company') },
];

export const overnightStayGraduateTypes = [
  // { value: 'shared', label: i18n.global.t('register.graduate.overnight_stay_type_list.shared') },
  // { value: 'double', label: i18n.global.t('register.graduate.overnight_stay_type_list.double') },
  { value: 'yes', label: i18n.global.t('register.graduate.overnight_stay_type_list.yes') },
  { value: 'no', label: i18n.global.t('register.graduate.overnight_stay_type_list.no') },
];

export const overnightStayEmployeeTypes = [
  { value: 'single', label: i18n.global.t('register.employee.overnight_stay_type_list.single') },
  { value: 'double', label: i18n.global.t('register.employee.overnight_stay_type_list.double') },
  { value: 'shared', label: i18n.global.t('register.employee.overnight_stay_type_list.shared') },
  { value: 'no', label: i18n.global.t('register.employee.overnight_stay_type_list.no') },
];

export const statusTypes = [
  { value: 'registered', label: i18n.global.t('register.status.registered') },
  { value: 'confirmed', label: i18n.global.t('register.status.confirmed') },
  { value: 'canceled', label: i18n.global.t('register.status.canceled') },
];
