import { reactive } from 'vue';
import ValidationError from '../api/error/ValidationError';
import { translateValidation } from '@/i18n';

export function validations() {
  return reactive({});
}

export function validationsRecord(validations, errors) {
  Object.keys(errors).forEach((key) => {
    validations[key] = errors[key].map((message) => translateValidation(message));
  });

  return true;
}

export function validationsClear(validations, field = 'all') {
  if (typeof field === 'undefined' || field === 'all' || field === '') {
    Object.keys(validations).forEach((key) => {
      delete validations[key];
    });
    return;
  }

  if (!field.includes('*')) {
    delete validations[field];
    return;
  }

  const regex = new RegExp(field.replace(/\./g, '\\.').replace(/\*/g, '.*'), 'g');
  Object.keys(validations).forEach((key) => {
    if (key.match(regex)) {
      delete validations[key];
    }
  });
}

export function handleValidationError(error) {
  if (error instanceof ValidationError) {
    return validationsRecord(this.validations, error.validations);
  }

  return false;
}

export function handleValidationClear(field = 'all') {
  validationsClear(this.validations, field);
}

export default {
  validations: validations(),
  handleValidationError,
  handleValidationClear,
};
