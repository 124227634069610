function init($target, Glide, defaultSettings) {
  const $track = document.createElement('div');
  $track.classList.add('track');
  $track.setAttribute('data-glide-el', 'track');

  const $slides = document.createElement('div');
  $slides.classList.add('slides');
  $track.appendChild($slides);

  // const $arrows = document.createElement('div');
  // $arrows.classList.add('arrows');
  // $arrows.setAttribute('data-glide-el', 'controls');
  // $arrows.innerHTML = `
  //   <button class="arrow -left" data-glide-dir="<" aria-label="Vorheriger Slide"></button>
  //   <button class="arrow -right" data-glide-dir=">" aria-label="Nächster Slide"></button>
  // `;

  const $bullets = document.createElement('div');
  $bullets.classList.add('bullets');
  $bullets.setAttribute('data-glide-el', 'controls[nav]');

  let counter = 0;
  while ($target.children.length > 0) {
    const $slide = $target.children[0];
    $slide.classList.add('slide');

    $slides.appendChild($slide);
    const $bullet = document.createElement('button');
    $bullet.classList.add('bullet');
    $bullet.setAttribute('data-glide-dir', `=${counter++}`);
    $bullet.setAttribute('aria-label', `${counter + 1}. Slide`);
    $bullets.appendChild($bullet);
  }

  $target.appendChild($track);
  // $target.appendChild($arrows);
  $target.appendChild($bullets);

  const settings = Object.assign({}, defaultSettings, {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    focusAt: 'center',
    gap: 20,
  });

  new Glide($target, settings).mount();
}

export default init;
