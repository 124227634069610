<template>
  <div class="btn-group pagination" v-if="pages.length > 1">
    <button v-for="page in pages" :key="`page-${page}`" type="button" class="btn btn-square no-arrow" :class="{'bg-yellow': page === props.page}" @click="emit('change', page)" v-text="page" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
  max: {
    type: Number,
    default: 7,
  },
});
const emit = defineEmits(['change']);

const pages = computed(() => {
  let startPage = 1;
  let endPage = props.count;

  if (props.max < props.count) {
    let pagesBefore = Math.floor(props.max / 2);
    let pagesAfter = Math.ceil(props.max / 2) - 1;

    if (props.page - pagesBefore > 0) {
      startPage = props.page - pagesBefore;
    }

    if (props.page + pagesAfter < props.count) {
      endPage = props.page + pagesAfter;
    }
  }

  return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
});
</script>
