<template>
  <BaseInput name="street" :required="true" />
  <BaseInput name="house_number" :required="true" />
  <BaseInput name="postcode" :required="true" />
  <BaseInput name="place" :required="true" />
</template>

<script>
import IsGroup from '@/mixins/input/IsGroup';
import BaseInput from '@/components/input/Input';

export default {
  name: 'AddressInput',
  components: { BaseInput },
  mixins: [IsGroup],

  created() {
    this.$nextTick(() => {
      if (this.currentValue === undefined) {
        this.updateInputValue({});
      }
    });
  },
};
</script>
