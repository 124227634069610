import api from '@/api/registrationGraduate';
import registerStore from '@/stores/Register';
import {RegistrationList} from "@/stores/helper/RegistrationList";
import {populate as populateCsv, listEntry, csvEntry, csvHeaders} from '@/stores/helper/registrationGraduate';

const populate = ['education', 'company', 'uploads'];

function getAgeBreak(date = null) {
  const today = date || new Date();
  const year = today.getFullYear() - 18;
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function createFilters(filterData) {
  const filters = [];

  if (filterData.name) {
    filters.push({
      $or: [
        {firstname: {$containsi: filterData.name}},
        {lastname: {$containsi: filterData.name}},
        {company: {name: {$containsi: filterData.name}}},
      ],
    });
  }

  if (filterData.age) {
    const ageBreak = getAgeBreak(registerStore.eventDate.value);

    switch (filterData.age) {
      case 'over18':
        filters.push({birthdate: {$lte: ageBreak}});
        break;
      case 'under18':
        filters.push({birthdate: {$gt: ageBreak}});
        break;
    }
  }

  if (filterData.status) {
    filters.push({status: {$eq: filterData.status}});
  }

  if (filterData.hasData) {
    filters.push({uploads_count: {$gt: 0}});
  }

  return {
    $and: filters,
  };
}

export class RegistrationGraduate extends RegistrationList {
  constructor() {
    super({
      createFilters,
      createEntry: listEntry,
      api,
      populate,
      csv: {
        populate: populateCsv,
        headerRowEntry: csvHeaders,
        createEntry: csvEntry,
        filenamePrefix: 'gala-absolvent'
      }
    });
  }
}

const registrationGraduate = new RegistrationGraduate();
export default registrationGraduate;
