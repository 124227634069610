import debounce from 'lodash.debounce';
import { animationEndEventName, transitionEndEventName } from './base';

const OPEN_CLASS = 'opened';
const HIDE_CLASS = 'hiding';
const MENU_HIDE_CLASS = 'hide';
const MENU_SHOW_CLASS = 'show';
const SUB_BUTTON_OPEN_CLASS = 'opened';

const MOBILE_BREAKPOINT = 1500;

// TODO set onDesktop depending on screen width

export class NavMain {
  constructor() {
    this.$el = null;
    this.$menu = null;
    this.$navButton = null;
    this.opened = false;
    this.onMobile = true;
  }

  init($el) {
    this.$el = $el;
    this.$menu = $el.querySelector('[role="menubar"]');
    this.$navButton = null;
    this.opened = false;
    this.onMobile = true;

    this.initNavButton();
    this.initSubLevels();
    this.initMobileMenuCheck();
  }

  initNavButton() {
    const $button = this.$el.querySelector('[data-nav-button]');
    if ($button) {
      $button.addEventListener('click', () => this.onNavButtonClick($button));
      this.$navButton = $button;
    }
  }

  initSubLevels() {
    this.$el.querySelectorAll('[role="menubar"] [aria-haspopup="true"]').forEach(($button) => {
      $button.addEventListener('click', () => this.onSubButtonClick($button));
    });
  }

  initMobileMenuCheck() {
    const check = () => {
      this.onMobile = window.innerWidth < MOBILE_BREAKPOINT;
    }

    check();
    window.addEventListener('resize', debounce(check, 300));
  }

  onNavButtonClick($button) {
    this.toggleButton(
      $button,
      'navOpen',
      'navClose'
    );
  }

  onSubButtonClick($button) {
    const toggleSub = () => {
      this.toggleButton(
        $button,
        'subButtonOpen',
        'subButtonClose',
      );
    };

    if(this.opened || !this.onMobile) {
      toggleSub();
    } else {
      this.navOpen(this.$navButton, toggleSub);
    }
  }

  subButtonOpen($button) {
    // TODO this need to be improved, if an other sub menu is already opened it looks a bit wierd on desktop
    this.closeAllSubMenus();

    $button.classList.add(SUB_BUTTON_OPEN_CLASS);
    $button.setAttribute('aria-expanded', 'true');
  }

  subButtonClose($button) {
    $button.classList.remove(SUB_BUTTON_OPEN_CLASS);
    $button.setAttribute('aria-expanded', 'false');
  }

  navOpen($button, onFinish = () => {}) {
    this.onceAfterAnimation(this.$menu, () => {
      this.onceAfterTransition(this.$el, () => {
        onFinish();
      });

      this.$el.classList.add(OPEN_CLASS);
      this.$menu.classList.remove(MENU_HIDE_CLASS);
    });

    $button.setAttribute('aria-expanded', 'true');
    this.$menu.classList.add(MENU_HIDE_CLASS);
    this.opened = true;
  }

  navClose($button, onFinish = () => {}) {
    this.onceAfterTransition(this.$el, () => {
      this.onceAfterAnimation(this.$menu, () => {
        this.$menu.classList.remove(MENU_SHOW_CLASS);
        onFinish();
      });

      // close all sub menu buttons
      this.closeAllSubMenus();

      this.$el.classList.remove(OPEN_CLASS, HIDE_CLASS);
      this.$menu.classList.add(MENU_SHOW_CLASS);
      this.opened = false;
    });

    $button.setAttribute('aria-expanded', 'false');
    this.$el.classList.add(HIDE_CLASS);
  }

  closeAllSubMenus() {
    this.$menu.querySelectorAll('[aria-expanded="true"]').forEach($button => this.subButtonClose($button))
  }

  onceAfterTransition($target, callback) {
    this.onceAfterEffect($target, callback, transitionEndEventName)
  }

  onceAfterAnimation($target, callback) {
    this.onceAfterEffect($target, callback, animationEndEventName)
  }

  onceAfterEffect($target, callback, eventName) {
    const action = (event) => {
      if(event.target === $target) {
        callback();
        $target.removeEventListener(eventName, action);
      }
    }

    $target.addEventListener(eventName, action);
  }

  /**
   *
   * @param $button
   * @param {string} openFunction
   * @param {string} closeFunction
   */
  toggleButton($button, openFunction, closeFunction) {
    const opened = $button.getAttribute('aria-expanded') === 'true';

    // is opened => close it
    if (opened) {
      this[closeFunction]($button);
      return;
    }

    this[openFunction]($button);
  }
}

export function initNavMain(selector = '[data-nav="main"]') {
  const $el = document.querySelector(selector);

  return $el ? (new NavMain()).init($el) : null;
}
