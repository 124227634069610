import IsInputField from './IsInputField';

export default {
  mixins: [IsInputField],

  computed: {
    currentValue() {
      let value = this.hasInputModel
        ? this.$attrs.modelValue
        : this.readNestedValue(this.completeName, this.model);

      if (typeof value !== 'boolean' && value !== undefined) {
        value = this.castValue(value);
        this.updateInputValue(value);
      }

      return value;
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.currentValue === undefined) {
        this.updateInputValue(false);
      }
    });
  },

  methods: {
    castValue(value) {
      switch (typeof value) {
        case 'undefined':
          return false;
        case 'number':
          return value === 1;
        case 'string':
          return value === '1' || value === 'on';
      }

      return false;
    },
  },
};
