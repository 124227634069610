import { reactive } from 'vue';

class SvgLoader {
  constructor() {
    this.svgs = reactive({});
  }

  async content(url) {
    if (!this.svgs[url]) {
      await this.load(url);
    }

    return this.svgs[url];
  }

  async load(url) {
    // TODO may improve svg loading so that the svg gets cached by browser
    // TODO handle error if one happens by using try catch
    const response = await window.axios.get(url);

    Object.assign(this.svgs, {
      [url]: response.data,
    });
  }
}

const svgLoader = new SvgLoader();
export default svgLoader;
