<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <RadiobuttonsInput name="overnight_stay" :list="overnightStayGraduateTypes" :required="true" />

    <div v-if="show" class="alert warning" v-html="info.general" />

    <template v-if="show">
      <RepeatGroup
        name="overnight_stay_room_mates"
        component-name="PersonRepeatItem"
        :min-length="minRoomMates"
        :max-length="maxRoomMates"
      />
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import { overnightStayGraduateTypes } from '@/defaults';
import registerStore from '@/stores/Register';
import IsFieldset from '@/mixins/input/IsFieldset';
import RadiobuttonsInput from '@/components/input/Radiobuttons';
import RepeatGroup from '@/components/input/group/RepeatGroup';

export default {
  name: 'OvernightStay',
  components: { RepeatGroup, RadiobuttonsInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'overnight_stay',
      type: String,
    },
  },

  setup() {
    return {
      overnightStayGraduateTypes,
      info: registerStore.overnightStayInfo.graduate,
      show: ref(false),
      minRoomMates: ref(0),
      maxRoomMates: ref(1),
    };
  },

  created() {
    this.$nextTick(() => {
      this.updateList(this.type);
    })
  },

  computed: {
    type() {
      return this.currentValue.overnight_stay;
    },
  },

  watch: {
    type(value) {
      this.updateList(value);
    },
  },

  methods: {
    updateList(type) {
      switch (type) {
        // case 'shared':
        //   this.minRoomMates = 1;
        //   this.maxRoomMates = 3;
        //   break;
        // case 'double':
        //   this.minRoomMates = 1;
        //   this.maxRoomMates = 1;
        //   break;
        case 'yes':
          this.minRoomMates = 0;
          this.maxRoomMates = 3;
          break;
        default:
          this.minRoomMates = 0;
          this.maxRoomMates = 0;
          break;
      }

      this.show = this.maxRoomMates > 0;
    },
  },
};
</script>
