<template>
  <teleport v-if="headline" to=".app > .header">
    <div class="teaser">
      <picture v-if="headerImage" class="image">
        <img :src="headerImage.src" :alt="headerImage.alt" />
      </picture>
      <h1 class="headline no-underline" v-html="headline" />
    </div>
    <div v-if="page.subtitle" class="subheadline" v-text="page.subtitle" />
  </teleport>

  <div class="article">
    <component
      v-for="(block, index) in page.content"
      :key="`content-block-${index}`"
      :is="block.component"
      v-bind="block.data"
    />
  </div>

  <ContactInfo v-if="page.contact" :contact="page.contact" />
</template>

<script setup>
import pages from '@/stores/Pages';
import { computed } from 'vue';
import ContactInfo from "@/components/ContactInfo";

const page = pages.home;
pages.loadHome();
const headline = computed(() => {
  if (!page || !page.title) {
    return null;
  }

  return page.title.replace(':heart:', '<i class="icon -heart"></i>');
});

const headerImage = computed(() => {
  if (!page.header_img || !page.header_img.data || !page.header_img.data.attributes) {
    return null;
  }

  const { url, alternativeText } = page.header_img.data.attributes;

  return {
    src: `${process.env.VUE_APP_STRAPI_API_URL}${url}`,
    alt: alternativeText || undefined,
  };
});
</script>
