import ModelInputField from './ModelInputField';

export default {
  mixins: [ModelInputField],

  props: {
    index: {
      required: true,
      type: Number,
    },
  },

  computed: {
    completeName() {
      return this.buildPrefixedName('namePrefix', 'inputModelPrefix', 'index');
    },

    inputModelPrefix() {
      return this.completeName;
    },

    validationName() {
      return this.buildPrefixedName('validationPrefix', 'inputValidationPrefix', 'index');
    },

    inputValidationPrefix() {
      return this.validationName;
    },

    inputIdPrefix() {
      return this.buildHtmlId(this.buildPrefixedName('idPrefix', 'inputIdPrefix', 'index'));
    },

    inputLabelTranslation() {
      return typeof this.$parent.inputLabelTranslation === 'string'
        ? this.$parent.inputLabelTranslation
        : '';
    },
  },
};
