<template>
  <InputField v-bind="inputFieldProps">
    <div class="input date-select">
      <select
        class="input -select -year"
        :id="inputId"
        placholder="YYYY"
        :disabled="disabled"
        @change="updateYear($event.target.value)"
      >
        <option value="" />
        <option
          v-for="value in years"
          :key="`input-select-year-${value}`"
          v-text="value"
          :value="value"
          :selected="value === year"
        />
      </select>

      <select
        class="input -select -month"
        :id="inputId"
        placholder="MM"
        :disabled="disabled"
        @change="updateMonth($event.target.value)"
      >
        <option value="" />
        <option
          v-for="value in months"
          :key="`input-select-month-${value}`"
          v-text="value"
          :value="value"
          :selected="value === month"
        />
      </select>

      <select
        class="input -select -day"
        :id="inputId"
        placholder="DD"
        :disabled="disabled"
        @change="updateDay($event.target.value)"
      >
        <option value="" />
        <option
          v-for="value in days"
          :key="`input-select-day-${value}`"
          v-text="value"
          :value="value"
          :selected="value === day"
        />
      </select>
    </div>
  </InputField>
</template>

<script>
import { ref } from 'vue';
import ModelInputField from '../../mixins/input/ModelInputField';
import IsInputField from '../../mixins/input/IsInputField';

const formatNumber = (num) => String(num).padStart(2, '0')
const days = Array.from({ length: 31 }, (_, i) => formatNumber(i + 1));
const months = Array.from({ length: 12 }, (_, i) => formatNumber(i + 1));

export default {
  name: 'DateSelect',
  mixins: [IsInputField],

  props: {
    yearStart: {
      default: 1970,
      type: Number,
    },
    yearEnd: {
      default: 2040,
      type: Number,
    },
  },

  setup(props) {
    const day = ref('');
    const month = ref('');
    const year = ref('');
    const years = Array.from(
      { length: props.yearEnd - props.yearStart },
      (_, i) => `${props.yearEnd - i}`
    );

    return {
      days,
      months,
      years,
      day,
      month,
      year,
    };
  },

  created() {
    this.updateDateData();
  },

  watch: {
    currentValue() {
      this.updateDateData();
    },
  },

  methods: {
    updateInputValue(value, name = null) {
      if (value === undefined) {
        return ModelInputField.methods.updateInputValue.call(this, undefined, name);
      }

      ModelInputField.methods.updateInputValue.call(this, this.buildDate(), name);
    },

    buildDate() {
      return (this.year && this.month && this.day)
        ? `${this.year}-${this.month}-${this.day}`
        : undefined;
    },

    updateDay(value) {
      this.day = value;
      this.updateInputValue(true);
    },

    updateMonth(value) {
      this.month = value;
      this.updateInputValue(true);
    },

    updateYear(value) {
      this.year = value;
      this.updateInputValue(true);
    },

    updateDateData() {
      if (this.currentValue && this.currentValue !== this.buildDate()) {
        const date = this.currentValue.split('-');
        this.year = date[0];
        this.month = date[1];
        this.day = date[2];
      }
    },
  },
};
</script>
