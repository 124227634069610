<template>
  <div>
    <slot />

    <SelectInput name="gender" :options="genders" :required="true" default-text="" />
    <BaseInput name="firstname" :required="true" />
    <BaseInput name="lastname" :required="true" />
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsRepeatItem from '@/mixins/input/IsRepeatItem';
import SelectInput from '@/components/input/Select';
import BaseInput from '@/components/input/Input';

export default {
  name: 'PersonRepeatItem',
  components: { BaseInput, SelectInput },
  mixins: [IsRepeatItem],

  setup() {
    return {
      genders: registerStore.selectGenders(),
    };
  },
};
</script>
