<template>
  <article class="article">
    <header class="header">
      <h1 class="headline" v-text="$t('not_found.headline')" />
      <span class="subheadline" v-text="$t('not_found.subheadline')" />
    </header>

    <div class="text">
    <p v-text="$t('not_found.text')" />
    </div>

    <div class="btn-group">
      <router-link class="btn btn-square bg-yellow" :to="{name: 'home'}" v-text="$t('not_found.link_text')" />
    </div>
  </article>
</template>
