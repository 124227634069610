// @see https://402.ecma-international.org/2.0/#sec-intl-datetimeformat-constructor for form infos

export default {
  de: {
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },

    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
};
