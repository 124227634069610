<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <BaseInput name="client_number" />
    <BaseInput name="training_company" :required="true" />

    <AddressInput name="training_company_address" />

    <BaseInput name="instructor" :required="true" />

    <CheckboxesInput
      name="education_officers"
      :list="educationOfficers"
      cast="int"
      :required="true"
    />
    <SelectFeeTextInput
      name="seminar_location"
      :options="seminarLocations"
      default-text=""
      cast="int"
      :required="true"
    />
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsFieldset from '@/mixins/input/IsFieldset';
import BaseInput from '@/components/input/Input';
import AddressInput from '@/components/input/group/Address';
import CheckboxesInput from '@/components/input/Checkboxes';
import SelectFeeTextInput from '@/components/input/SelectFreeText';

export default {
  name: 'TrainingCompany',
  components: { SelectFeeTextInput, CheckboxesInput, AddressInput, BaseInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'training_company',
      type: String,
    },
  },

  setup() {
    return {
      educationOfficers: registerStore.selectEducationOfficers(),
      seminarLocations: registerStore.selectSeminarLocations(),
    };
  },
};
</script>
