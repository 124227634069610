import { ref } from 'vue';
import registerStore from '@/stores/Register';
import { calculateAge } from '@/stores/helper/registrationGraduate';

export default {
  computed: {
    birthdate() {
      return null;
    },
  },

  data() {
    return {
      underEighteen: ref(false),
    };
  },

  watch: {
    birthdate(value) {
      if (value) {
        // get age when the event is taking place.
        const age = calculateAge(new Date(value), registerStore.eventDate.value);
        this.underEighteen = age < 18;
      }
    },
  },
};
