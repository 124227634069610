import apiRegistrationGraduate from '@/api/registrationGraduate';
import apiRegistrationEmployee from '@/api/registrationEmployee';
import {prepareData as prepareGraduateData, populate as populateGraduate} from '@/stores/helper/registrationGraduate';
import {prepareData as prepareEmployeeData, populate as populateEmployee} from '@/stores/helper/registrationEmployee';

const hideKeys = ['createdAt', 'updatedAt'];

export function registrationConfig(type) {
  switch (type) {
    case 'graduate':
      return {
        api: apiRegistrationGraduate,
        populate: populateGraduate,
        prepareData: prepareGraduateData,
      };
    case 'employee':
      return {
        api: apiRegistrationEmployee,
        populate: populateEmployee,
        prepareData: prepareEmployeeData,
      };
    default:
      throw new Error(
        `Using undefined config type "${type}" when calling registrationConfig helper function.`
      );
  }
}

export function prepareData(data) {
  const parsed = {};

  Object.keys(data).forEach((key) => {
    if (hideKeys.includes(key)) {
      return;
    }

    if (key === 'uploads') {
      parsed['uploads'] = prepareUploadEntryData(data[key]);
      return;
    }

    const entry = prepareDataEntry(data[key]);

    if (entry !== undefined) {
      parsed[key] = entry;
    }
  });

  return parsed;
}

function prepareDataEntry(entry) {
  if (entry === null || entry === undefined) {
    return undefined;
  }

  if (typeof entry === 'object') {
    return prepareDataObject(entry);
  }

  return entry;
}

function prepareDataObject(entry) {
  if (
    entry.data &&
    typeof entry.data === 'object' &&
    Object.hasOwnProperty.call(entry.data, 'id') &&
    Object.hasOwnProperty.call(entry.data, 'attributes')
  ) {
    return entry.data.id;
  }

  if (entry.data && Array.isArray(entry.data)) {
    if (
      entry.data.length > 0 &&
      typeof entry.data[0] === 'object' &&
      Object.hasOwnProperty.call(entry.data[0], 'id') &&
      Object.hasOwnProperty.call(entry.data[0], 'attributes')
    ) {
      const ids = [];

      entry.data.forEach((item) => {
        if (typeof item.id === 'number') {
          ids.push(item.id);
        }
      });

      return ids;
    }

    return entry.data;
  }

  if (typeof entry.data === 'object' && entry.data === null) {
    return null;
  }

  return entry;
}

function prepareUploadEntryData(entry) {
  if (entry === null || entry === undefined) {
    return undefined;
  }

  if (
    Array.isArray(entry.data) &&
    entry.data.length > 0 &&
    typeof entry.data[0] === 'object' &&
    Object.hasOwnProperty.call(entry.data[0], 'id') &&
    Object.hasOwnProperty.call(entry.data[0], 'attributes')
  ) {
    const files = [];

    entry.data.forEach((item) => {
      files.push({
        id: item.id,
        name: item.attributes.name,
        size: item.attributes.size,
      });
    });

    return files;
  }

  return [];
}

export function prepareAlert(entry, key) {
  const {headline, text = ''} = entry[key] || {};

  return {headline, text};
}
