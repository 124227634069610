<template>
  <a v-text="text" class="btn btn-square" v-bind="attributes" />
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  new_tab: {
    type: Boolean,
    default: false,
  }
});

const attributes = computed(() => {
  const attrs = {
    href: props.link,
  };

  if (props.new_tab === true) {
    attrs.target = '_blank';
    attrs.rel = 'noopener noreferrer';
  }

  return attrs;
})

</script>
