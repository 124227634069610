import { ref, computed } from 'vue';
import auth from '@/api/auth';
import ApiError from '@/api/error/ApiError';
import AccessError from '@/api/error/AccessError';
import { readCookie, writeCookie } from '@/assets/core/js/base';

const USER_TYPE_MAP = {
  [process.env.VUE_APP_STRAPI_USER_FRONTEND]: 'user',
  [process.env.VUE_APP_STRAPI_USER_BACKEND]: 'admin',
};

const AUTH_COOKIE_NAME = process.env.VUE_APP_AUTH_COOKIE_NAME;
const AUTH_COOKIE_LIVE_TIME = 2592000; // 30 days in seconds 30 * 24 * 60 * 60



class CurrentUser {
  constructor() {
    this._isLoggedIn = ref(false);
    this._authenticated = ref(false);
    this._userType = ref('');
  }

  isLoggedIn() {
    return this._isLoggedIn.value;
  }

  isAdmin() {
    return this._userType.value === 'admin';
  }

  async login(credentials) {
    this._fill(await auth.login(credentials));
  }

  async loginFrontend(password) {
    await this.login({ identifier: process.env.VUE_APP_STRAPI_USER_FRONTEND, password });
  }

  async loginBackend(password) {
    await this.login({ identifier: process.env.VUE_APP_STRAPI_USER_BACKEND, password });
  }

  async logout() {
    await auth.logout();
    this._unset();
  }

  async tryAuthenticate() {
    if (this._authenticated.value) {
      return this.isLoggedIn();
    }

    await this.tryAuthenticateByCookie();

    return this.isLoggedIn();
  }

  async tryAuthenticateByCookie() {
    const cookieValue = readCookie(AUTH_COOKIE_NAME);
    if (typeof cookieValue !== 'string') {
      return;
    }

    const jwt = atob(cookieValue);
    if (!jwt) {
      this._unset();
      return;
    }

    window.axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    try {
      const user = await auth.me();
      this._fill({ jwt, user });
    } catch (error) {
      if (error instanceof AccessError) {
        this._unset();
        this._authenticated.value = true;
      } else {
        throw error;
      }
    }
  }

  _fill({ jwt, user }) {
    if (!jwt || !user || user.blocked !== false || !USER_TYPE_MAP[user.username]) {
      throw new ApiError("User can't be authenticated got invalid api response.");
    }

    window.axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    const cookieValue = btoa(`${jwt}`);
    writeCookie(AUTH_COOKIE_NAME, cookieValue, AUTH_COOKIE_LIVE_TIME);

    this._isLoggedIn.value = true;
    this._authenticated.value = true;
    this._userType.value = USER_TYPE_MAP[user.username];
  }

  _unset() {
    window.axios.defaults.headers.common['Authorization'] = undefined;
    writeCookie(AUTH_COOKIE_NAME, '', -999);

    this._isLoggedIn.value = false;
    this._authenticated.value = false;
  }
}

const currentUser = new CurrentUser();

export default currentUser;

export const isLoggedIn = computed(() => currentUser.isLoggedIn());
