<template>
  <form class="form filter" @submit.stop.prevent="onSubmit">
    <BaseInput v-model="filter.name" name="name" :label="$t(`${filterTranslation}.name`)"/>
    <SelectInput
      v-model="filter.age"
      name="age"
      :label="$t(`${filterTranslation}.age`)"
      :options="ageSelect"
      default-text=""
    />
    <SelectInput
      v-model="filter.status"
      name="status"
      :label="$t(`${filterTranslation}.status`)"
      :options="statusTypes"
      default-text=""
    />
    <CheckboxInput
      v-model="filter.hasData"
      name="has_data"
      :label="$t(`${filterTranslation}.has_data`)"
    />

    <div class="btn-group">
      <button
        type="reset"
        class="btn btn-square bg-yellow no-arrow"
        v-text="$t(`${filterTranslation}.btn_reset`)"
        @click.stop.prevent="onReset"
      />

      <button
        type="submit"
        class="btn btn-square bg-yellow"
        v-text="$t(`${filterTranslation}.btn_submit`)"
      />
    </div>
  </form>

  <div class="table-wrap">
    <table class="table">
      <thead>
      <tr>
        <th v-text="$t(`${translation}.education`)"/>
        <th v-text="$t(`${translation}.company`)"/>
        <th v-text="$t(`${translation}.salutation`)"/>
        <th v-text="$t(`${translation}.firstname`)"/>
        <th v-text="$t(`${translation}.lastname`)"/>
        <th v-text="$t(`${translation}.email`)"/>
        <th v-text="$t(`${translation}.birthdate`)"/>
        <th v-text="$t(`${translation}.park_visit`)"/>
        <th v-text="$t(`${translation}.overnight_stay`)"/>
        <th v-text="$t(`${translation}.uploads`)"/>
        <th v-text="$t(`${translation}.status`)"/>
        <th/>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in store.entries" :key="index">
        <td v-text="item.education"/>
        <td v-text="item.company"/>
        <td v-text="item.salutation"/>
        <td v-text="item.firstname"/>
        <td v-text="item.lastname"/>
        <td v-text="item.email"/>
        <td v-text="item.birthdate"/>
        <td v-text="item.park_visit"/>
        <td v-text="item.overnight_stay"/>
        <td>
          <div class="links">
            <a
              v-for="(upload, uploadIndex) in item.uploads"
              :key="`${index}-${uploadIndex}`"
              v-text="upload.name"
              target="_blank"
              rel="noreferrer noopener"
              :href="upload.url"
            />
          </div>
        </td>
        <td>
          <SelectInput
            v-model="item.status"
            :name="`status-change-${index}`"
            :options="statusTypes"
            @input="(value) => onStatusChange(item, value)"
            default-text=""
          />
        </td>
        <td>
          <div class="btn-group">
            <router-link
              class="btn btn-round bg-yellow icon-after -external-link"
              :to="{ name: 'register.edit.graduate', params: { hash: item.hash } }"
              v-text="$t(`${translation}.btn_edit`)"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <NumberPagination
    :page="store.meta.page"
    :count="store.meta.pageCount"
    @change="onPageChange"
  />

  <div class="btn-group" style="margin-top: 2rem">
    <button
      style="margin-left: auto"
      class="btn btn-square bg-yellow no-arrow"
      v-text="$t(`${filterTranslation}.btn_export`)"
      @click.stop.prevent="onExport"
    />
  </div>
</template>

<script setup>
import {ref, readonly} from 'vue';
import i18n from '@/i18n';
import {statusTypes} from '@/defaults';
import store from '@/stores/RegistrationGraduate';
import useRegistrationListPage from "@/mixins/registrationListPage";
import BaseInput from '@/components/input/Input';
import SelectInput from '@/components/input/Select';
import CheckboxInput from '@/components/input/Checkbox';
import NumberPagination from '@/components/nav/NumberPagination';

const translation = readonly(ref('backend.graduate'));
const filterTranslation = readonly(ref(`${translation.value}.filter`));

const ageSelect = [
  {value: 'under18', label: i18n.global.t(`${filterTranslation.value}.age_select.under_18`)},
  {value: 'over18', label: i18n.global.t(`${filterTranslation.value}.age_select.over_18`)},
];

const {filter, onSubmit, onReset, onPageChange, onStatusChange, onExport} = useRegistrationListPage(store);
</script>
