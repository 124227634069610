<template>
  <div v-if="bindings" class="video">
    <video controls>
      <source v-for="(source, index) in bindings.sources" :key="`video-source-${index}`" v-bind="source" />
    </video>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  file: {
    required: true,
  },
});

const bindings = computed(() => {
  if (!props.file || !props.file.data || !props.file.data.attributes) {
    return null;
  }

  const {url, mime, alternativeText} = props.file.data.attributes;
  const sources = [{
    type: mime,
    src: `${process.env.VUE_APP_STRAPI_API_URL}${url}`,
  }];

  return {
    sources,
    alt: alternativeText || undefined,
  }
});
</script>
