<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <RepeatGroup
      name="additional_person"
      component-name="AdditionalEmployeeRepeatItem"
      :min-length="0"
      :max-length="100"
    />
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsFieldset from '@/mixins/input/IsFieldset';
import RepeatGroup from '@/components/input/group/RepeatGroup';

export default {
  name: 'AdditionalPerson',
  components: { RepeatGroup },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'additional_person',
      type: String,
    },
  },

  setup() {
    return {
      genders: registerStore.selectGenders(),
      selectCompanies: registerStore.selectCompanies(),
    };
  },
};
</script>
