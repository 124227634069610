import {prepareData as prepareBaseData} from '@/stores/helper/register';
import {
  addressArray,
  booleanText,
  companyText,
  dateText,
  overnightStayRoomMateArray,
  salutationText,
  statusText
} from "@/stores/helper/entry-filed-generation";
import {overnightStayEmployeeTypes, typesToObject} from "@/defaults";

export const populate = [
  'company',
  'company_address',
  'billing_address',
  'overnight_stay_room_mates',
  'additional_person',
  'additional_person.address',
  'additional_person.billing_address',
  'additional_person.overnight_stay_room_mates',
];

export function prepareData(data) {
  const prepared = prepareBaseData(data);

  if (prepared.additional_person) {
    if (!Array.isArray(prepared.additional_person) || prepared.additional_person.length < 0) {
      delete prepared.additional_person;
    } else {
      prepared.additional_person.map((person) => prepareAdditionalPerson(person))
    }
  }

  return prepared;
}

function prepareAdditionalPerson(person) {
  if (person.address_different === false) {
    delete person.billing_address_different;
    delete person.market;

    return person;
  }

  return person;
}

export function listEntry(attributes) {
  return {
    hash: attributes.hash,
    status: attributes.status,
    salutation: salutationText(attributes.gender),
    firstname: attributes.firstname,
    lastname: attributes.lastname,
    email: attributes.email,
  };
}

export const csvHeaders = [
  'E-Mail-Address',
  'Typ',
  'Status',
  'Anrede',
  'Vorname',
  'Nachname',
  'Telefonnummer',
  'Firma',
  'Kundennummer',
  'Markt/Abteilung',
  'Firma Straße',
  'Firma Hausnummer',
  'Firma PLZ',
  'Firma Ort',
  'Abweichende Rechnungsadresse',
  'Rechnung Straße',
  'Rechnung Hausnummer',
  'Rechnung PLZ',
  'Rechnung Ort',
  'Unverträglichkeiten',
  'Übernachtung',
  'Zimmerp. 1 Anrede',
  'Zimmerp. 1 Vorname',
  'Zimmerp. 1 Nachname',
  'Zimmerp. 2 Anrede',
  'Zimmerp. 2 Vorname',
  'Zimmerp. 2 Nachname',
  'Zimmerp. 3 Anrede',
  'Zimmerp. 3 Vorname',
  'Zimmerp. 3 Nachname',
  'Anmeldedatum',
  'Weitere Personen'
];

const overnightSayTexts = typesToObject(overnightStayEmployeeTypes);

/**
 * Generate an entry for the csv the number and order of array items need to match the csvHeaders array
 * @param entry
 * @returns {*[]}
 */
export function csvEntry(entry) {

  const additionalPersons = (personsArray) => {
    if (!Array.isArray(personsArray)) {
      return '';
    }

    const persons = [];
    personsArray.forEach((person) => {
      const data = [
        salutationText(person.gender),
        person.firstname,
        person.lastname,
        person.market,
        ...(person.address_different ? addressArray(person.address) : []),
        ...(person.billing_address_different ? addressArray(person.billing_address) : []),
        overnightStayText(person.overnight_stay)
      ];

      if (person.overnight_stay_room_mates) {
        person.overnight_stay_room_mates.forEach((overnightSayRoomMate, index) => data.push(...overnightStayRoomMateArray(person.overnight_stay_room_mates, index)))
      }

      persons.push(data.join(','));
    });

    return persons.join(';');
  }

  const overnightStayText = (value) => {
    return overnightSayTexts[value] || '';
  }

  return [
    entry.email, //'E-Mail-Adresse',
    'Mitarbeiter:in', //'Type',
    statusText(entry.status), //'Status',
    salutationText(entry.gender), //'Anrede',
    entry.firstname, //'Vorname',
    entry.lastname, //'Nachname',
    entry.phone, //'Telefonnummer',
    companyText(entry.company), //'Firma',

    entry.customer_number, //'Kundennummer',
    entry.market, //'Markt/Abteilung',
    ...addressArray(entry.company_address), //'Firma Straße', 'Firma Hausnummer', 'Firma PLZ', 'Firma Ort',
    booleanText(entry.billing_address_different), //'Abweichende Rechnungsadresse',
    ...addressArray(entry.billing_address), //'Rechnung Straße', 'Rechnung Hausnummer', 'Rechnung PLZ', 'Rechnung Ort',
    entry.intolerances, //'Unverträglichkeiten',
    overnightStayText(entry.overnight_stay), //'Übernachtung',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 0), //'Zimmerp. 1 Anrede', 'Zimmerp. 1 Vorname', 'Zimmerp. 1 Nachname',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 1), //'Zimmerp. 2 Anrede', 'Zimmerp. 2 Vorname', 'Zimmerp. 2 Nachname',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 2), //'Zimmerp. 3 Anrede', 'Zimmerp. 3 Vorname', 'Zimmerp. 3 Nachname',
    dateText(entry.createdAt), //'Anmeldedatum',
    additionalPersons(entry.additional_person), //'Weitere Personen'
  ]
}
