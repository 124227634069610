<template>
  <form v-if="loaded && !updated" class="form" @submit.stop.prevent="onSubmit">
    <component :is="`register-${type}`" />

    <div class="btn-group">
      <button
        type="button"
        class="btn btn-square btn-lg bg-gray-light no-arrow"
        v-t="'register.cancel'"
        @click.stop.prevent="onCancel"
      />
      <button
        type="submit"
        class="btn btn-square btn-lg bg-yellow"
        v-text="$t('register.submit')"
      />
    </div>
  </form>
  <FormAlert v-else-if="updated" :alert="alert">
    <template #buttons>
      <router-link :to="{ name: 'home' }" class="btn btn-square bg-alert"
        >Zur Startseite
      </router-link>
    </template>
  </FormAlert>
</template>

<script>
import { reactive, readonly, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import handlesValidations from '@/mixins/validations';
import registerStore from '@/stores/Register';
import ApiError from '@/api/error/ApiError';
import RadiobuttonsInput from '@/components/input/Radiobuttons';
import FormAlert from '@/components/form/FormAlert';

function getRegistrationType(routeName) {
  switch (routeName) {
    case 'register.edit.graduate':
      return 'graduate';
    case 'register.edit.employee':
      return 'employee';
    default:
      return '';
  }
}

export default {
  name: 'RegisterPage',
  components: { RadiobuttonsInput, FormAlert },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const hash = readonly(ref(route.params.hash));
    const type = readonly(ref(getRegistrationType(route.name))); // TODO make variable depending on route
    const loadedData = ref(false);
    const model = reactive({});
    const alert = reactive({});

    registerStore.load();
    registerStore
      .loadEntry(type.value, hash.value)
      .then((data) => {
        Object.assign(model, data);
        loadedData.value = true;
        Object.assign(alert, registerStore.success.updated[type.value]);
      })
      .catch((error) => {
        if (error instanceof ApiError && error.status === 404) {
          router.push({ name: 'home' });
          return;
        }
        throw error;
      });

    return {
      hash,
      model,
      type,
      alert,
      loaded: computed(() => registerStore.loaded.value && loadedData.value),
      updated: ref(false),
      inputLabelTranslation: readonly(ref('register')),
      ...handlesValidations,
    };
  },

  methods: {
    async onSubmit() {
      this.handleValidationClear();
      try {
        await registerStore.updateEntry(this.type, this.hash, { ...this.model });
        this.updated = true;
      } catch (error) {
        if (this.handleValidationError(error)) {
          return;
        }

        // TODO improve handling
        throw error;
      }
    },

    async onCancel() {
      if (await registerStore.cancel(this.type, this.hash)) {
        Object.assign(this.alert, registerStore.success.canceled[this.type]);
        this.updated = true;
      }
    },
  },
};
</script>
