export const defaultGlideSettings = {
  classes: {
    swipeable: '-swipeable',
    dragging: '-dragging',
    direction: {
      ltr: '-ltr',
      rtl: '-rtl',
    },
    type: {
      slider: 'is-slider',
      carousel: 'is-carousel',
    },
    slide: {
      clone: '-clone',
      active: '-active',
    },
    arrow: {
      disabled: '-disabled',
    },
    nav: {
      active: '-active',
    },
  },
}

function initVariations(variations, Glide) {
  variations.forEach((variation) => {
    variation.$targets.forEach(($target) => {
      // remove element if no slides exist
      if ($target.children.length <= 0) {
        $target.parentNode.removeChild($target);
        return;
      }

      // dont init slider if only one image
      if ($target.children.length <= 1) {
        return;
      }

      variation.callback($target, Glide, defaultGlideSettings);
    });
  });
}

export function initSlider(selectorMapping) {
  const variations = [];

  Object.keys(selectorMapping).forEach((selector) => {
    const $targets = document.querySelectorAll(selector);
    if ($targets.length > 0) {
      variations.push({
        callback: selectorMapping[selector],
        $targets,
      });
    }
  });

  if(variations.length <= 0) {
    return;
  }

  import('@glidejs/glide')
    .then(module => module.default)
    .then((Glide) => initVariations(variations, Glide))
}
