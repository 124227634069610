<template>
  <div class="fieldset">
    <PersonInfo />
    <CompanyInfo />

    <BaseInput name="intolerances" />

    <OvernightStay />
    <AdditionalPerson />

    <CheckboxInput name="data_protection" :label="labelDataProtection" :required="true" />
    <CheckboxInput
      name="participation_conditions"
      :label="labelParticipationConditions"
      :required="true"
    />
    <CheckboxInput name="informed_supervisor" :required="true" />

    <div class="alert info">
      <div class="headline" v-text="cancellationAlert.headline" />
      <div class="content" v-html="cancellationAlert.text" />
    </div>
  </div>
</template>
<script>
import IsGroup from '@/mixins/input/IsGroup';
import PersonInfo from '@/components/form/employee/PersonInfo';
import CheckboxInput from '@/components/input/Checkbox';
import registerStore from '@/stores/Register';
import CompanyInfo from '@/components/form/employee/CompanyInfo';
import OvernightStay from '@/components/form/employee/OvernightStay';
import AdditionalPerson from '@/components/form/employee/AdditionalPerson';
import BaseInput from '@/components/input/Input';

export default {
  name: 'RegisterEmployee',
  components: {
    BaseInput,
    AdditionalPerson,
    OvernightStay,
    CompanyInfo,
    PersonInfo,
    CheckboxInput,
  },
  mixins: [IsGroup],
  props: {
    nameTrans: {
      type: String,
      default: 'employee',
    },
  },

  setup() {
    return {
      info: registerStore.info,
      cancellationAlert: registerStore.cancellationAlert,
    };
  },

  computed: {
    labelDataProtection() {
      return this.linkTrans(registerStore.links.dataProtection, 'data_protection');
    },
    labelParticipationConditions() {
      return this.linkTrans(
        registerStore.links.participationConditions,
        'participation_conditions'
      );
    },
  },

  methods: {
    linkTrans(link, transKey) {
      return this.$t(`${this.inputLabelTranslation}.${transKey}`, {
        linkStart: `<a href="${link}" target="_blank" rel="noopener noreferrer">`,
        linkEnd: '</a>',
      });
    },
  },
};
</script>
