import ModelInputField from './ModelInputField';

export default {
  mixins: [ModelInputField],

  props: {
    name: {
      type: String,
      default: '',
    },
    nameTrans: {
      type: String,
      default: '',
    },
  },

  unmounted() {
    if (this.currentValue !== undefined) {
      this.updateInputValue(undefined);
    }
  },

  computed: {
    completeName() {
      return this.buildPrefixedName('namePrefix', 'inputModelPrefix', 'name');
    },

    inputModelPrefix() {
      return this.completeName;
    },

    validationName() {
      return this.buildPrefixedName('validationPrefix', 'inputValidationPrefix', 'name');
    },

    inputValidationPrefix() {
      return this.validationName;
    },

    inputIdPrefix() {
      return this.buildHtmlId(this.buildPrefixedName('idPrefix', 'inputIdPrefix', 'name'));
    },

    inputLabelTranslation() {
      const parent =
        typeof this.$parent.inputLabelTranslation === 'string'
          ? this.$parent.inputLabelTranslation
          : '';

      const nameTrans = this.nameTrans ? this.nameTrans : this.name;

      return nameTrans ? `${parent}.${nameTrans}` : parent;
    },
  },
};
