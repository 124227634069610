<template>
  <div class="fieldset">
    <SelectFeeTextInput
      name="education"
      :options="selectEducationTypes"
      default-text=""
      cast="int"
      :required="true"
    />
    <SelectInput
      name="company"
      :options="selectCompanies"
      default-text=""
      cast="int"
      :required="true"
    />

    <PersonInfo :has-personal-number="companyHasPersonalNumber" />
    <TrainingCompany />
    <GradeInfo />

    <SelectInput name="clothing_size" :options="selectClothingSizes" default-text="" />
    <BaseInput name="intolerances" />

    <OvernightStay />
    <ParkVisit />
    <!--<GuestInfo v-if="companyHasGuestRegistration" />-->

    <FileUpload name="register_uploads" class="bg bg-yellow" />

    <CheckboxInput name="data_protection" :label="labelDataProtection" :required="true" />
    <CheckboxInput
      name="participation_conditions"
      :label="labelParticipationConditions"
      :required="true"
    />
    <CheckboxInput name="informed_supervisor" :required="true" />

    <div class="alert info">
      <div class="headline" v-text="cancellationAlert.headline" />
      <div class="content" v-html="cancellationAlert.text" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import registerStore from '@/stores/Register';
import IsGroup from '@/mixins/input/IsGroup';
import SelectInput from '@/components/input/Select';
import SelectFeeTextInput from '@/components/input/SelectFreeText';
import PersonInfo from '@/components/form/graduate/PersonInfo';
import OvernightStay from '@/components/form/graduate/OvernightStay';
import TrainingCompany from '@/components/form/graduate/TrainingCompany';
import ParkVisit from '@/components/form/graduate/ParkVisit';
// import GuestInfo from '@/components/form/graduate/GuestInfo';
import CheckboxInput from '@/components/input/Checkbox';
import GradeInfo from '@/components/form/graduate/GradeInfo';
import FileUpload from '@/components/input/FileUpload';
import BaseInput from '@/components/input/Input';

export default {
  name: 'RegisterGraduate',
  components: {
    BaseInput,
    GradeInfo,
    CheckboxInput,
    // GuestInfo,
    ParkVisit,
    TrainingCompany,
    OvernightStay,
    PersonInfo,
    SelectInput,
    SelectFeeTextInput,
    FileUpload,
  },
  mixins: [IsGroup],
  props: {
    nameTrans: {
      type: String,
      default: 'graduate',
    },
  },

  setup() {
    return {
      selectEducationTypes: registerStore.selectEducationTypes(),
      selectCompanies: registerStore.selectCompanies(),
      selectClothingSizes: registerStore.selectClothingSizes(),
      info: registerStore.info,
      cancellationAlert: registerStore.cancellationAlert,
      companyHasPersonalNumber: ref(false),
      companyHasGuestRegistration: ref(false),
    };
  },

  computed: {
    company() {
      return this.currentValue.company;
    },
    labelDataProtection() {
      return this.linkTrans(registerStore.links.dataProtection, 'data_protection');
    },
    labelParticipationConditions() {
      return this.linkTrans(
        registerStore.links.participationConditions,
        'participation_conditions'
      );
    },
  },

  mounted() {
    if (this.company) {
      this.changeCompanyDependenedValues(this.company);
    }
  },

  watch: {
    company(newValue) {
      this.changeCompanyDependenedValues(newValue);
    },
  },

  methods: {
    linkTrans(link, transKey) {
      return this.$t(`${this.inputLabelTranslation}.${transKey}`, {
        linkStart: `<a href="${link}" target="_blank" rel="noopener noreferrer">`,
        linkEnd: '</a>',
      });
    },

    changeCompanyDependenedValues(companyId) {
      this.companyHasGuestRegistration = this.info.companiesWithGuestRegistration.includes(companyId);
      if (this.companyHasGuestRegistration) {
        this.updateInputValue(false, 'with_guest');
      }

      const companyHasPersonalNumber = !(companyId === null || this.info.companiesWithoutPersonalNumber.includes(companyId));
      if (companyHasPersonalNumber !== this.companyHasPersonalNumber) {
        this.companyHasPersonalNumber = companyHasPersonalNumber;
        this.updateInputValue(companyHasPersonalNumber ? '' : null, 'personal_number');
      }
    }
  },
};
</script>
