export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    description: {
      default: null,
      type: String,
    },
    hint: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    withLabelPlaceholder: {
      default: false,
      type: Boolean,
    },
  },
};
