<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <BaseInput name="customer_number" />
    <BaseInput name="market" :required="true" />
    <AddressInput name="company_address" />

    <ToggleSwitch name="billing_address_different" />
    <AddressInput name="billing_address" v-if="hasBillingAddress" />
  </div>
</template>

<script>
import IsFieldset from '@/mixins/input/IsFieldset';
import BaseInput from '@/components/input/Input';
import AddressInput from '@/components/input/group/Address';
import ToggleSwitch from '@/components/input/ToggleSwitch';

export default {
  name: 'CompanyInfo',
  components: { ToggleSwitch, AddressInput, BaseInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'company',
      type: String,
    },
  },
  computed: {
    hasBillingAddress() {
      return this.currentValue.billing_address_different;
    }
  },
};
</script>
