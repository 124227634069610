<template>
  <InputField v-bind="inputFieldProps">
    <input
      :type="type"
      :value="currentValue"
      :id="inputId"
      :disabled="disabled"
      :class="['input', `-${type}`]"
      placeholder=" "
      @input="updateInputValue($event.target.value)"
    />
  </InputField>
</template>

<script>
import IsInputField from '../../mixins/input/IsInputField';

export default {
  name: 'BaseInput',

  mixins: [IsInputField],

  props: {
    type: {
      default: 'text',
      type: String,
    },
    withLabelPlaceholder: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
