<template>
  <InputField v-bind="inputFieldProps">
    <select
      class="input -select"
      :id="inputId"
      placholder=" "
      :disabled="disabled"
      @change="updateInputValue($event.target.value)"
    >
      <option v-if="defaultText !== undefined" v-text="defaultText" value="nil"></option>
      <option
        v-for="{ value, label } in options"
        :key="`input-select-${value}`"
        v-text="label"
        :value="value"
        :selected="currentValue === castValue(value)"
      ></option>
    </select>
  </InputField>
</template>

<script>
import ModelInputField from '../../mixins/input/ModelInputField';
import IsInputField from '../../mixins/input/IsInputField';

export default {
  name: 'SelectInput',
  mixins: [IsInputField],

  props: {
    options: {
      required: true,
      type: Array,
    },
    defaultValue: {
      default: undefined,
    },
    defaultText: {
      default: undefined,
      type: String,
    },
    cast: {
      default: undefined,
      type: String,
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.currentValue === undefined) {
        const value = this.defaultText === undefined ? this.options[0].value : 'nil';

        this.updateInputValue(value);
      }
    });
  },

  methods: {
    updateInputValue(value, name = null) {
      ModelInputField.methods.updateInputValue.call(this, this.castValue(value), name);
    },

    castValue(value) {
      if (value === 'nil') {
        return this.defaultValue;
      }

      switch (this.cast) {
        case 'string':
          return '' + value;
        case 'int':
          return parseInt(value);
        case 'bool':
          return value === '' ? null : '' + value === '1';
        default:
          return value;
      }
    },
  },
};
</script>
