export class CsvCreator {
  constructor({ separator = ',', delimiter = '"', linebreak = '\n' } = {}) {
    this.separator = separator;
    this.delimiter = delimiter;
    this.linebreak = linebreak;

    this.delimiterRegex = new RegExp(`${delimiter}`, 'g');
    this.needDelimiterRegex = new RegExp(`(${separator}|${delimiter}|\n)`, 'g'); // delimiter is required if CSV cell value contains separator, delimiter or a linebreak
  }

  /**
   * Create csv string. Each array entry will be treated as row.
   *
   * @param {Array} array
   * @returns {string}
   */
  create(array) {
    return this.combine(array.map((row) => this.rowEntry(row)));
  }

  /**
   * combine all array elements with the linebreak
   *
   * @param {Array} array
   * @returns {string}
   */
  combine(array) {
    return array.join(this.linebreak);
  }

  /**
   * create row entry from an array or object by creating a cell from each entry and combining the cells with the separator
   * to a csv row
   * @param {Object|Array} value
   * @returns {string}
   */
  rowEntry(value) {
    if (typeof value !== 'object') {
      return '';
    }

    return (Array.isArray(value) ? value : Object.values(value))
      .map((cell) => this.cellEntry(cell))
      .join(this.separator);
  }

  /**
   * create csv cell entry by escaping the delimiter and surrounding the entry with the delimiter if required
   * @param {string} value
   * @returns {string}
   */
  cellEntry(value) {
    let entry =
      typeof value === 'string'
        ? value
        : value && typeof value.toString === 'function'
        ? value.toString()
        : '';
    if (!entry) {
      return '';
    }

    entry = entry.replace(this.delimiterRegex, `${this.delimiter}${this.delimiter}`); // escape delimiter
    return entry.search(this.needDelimiterRegex) >= 0 // surround entry with delimiter if required
      ? `${this.delimiter}${entry}${this.delimiter}`
      : entry;
  }
}
