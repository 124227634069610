<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <ToggleSwitch name="park_visit" />

    <div class="alert info" v-if="visit" v-html="info.text" />
  </div>
</template>

<script>
import IsFieldset from '@/mixins/input/IsFieldset';
import ToggleSwitch from '@/components/input/ToggleSwitch';
import registerStore from '@/stores/Register';

export default {
  name: 'ParkVisit',
  components: { ToggleSwitch },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'park_visit',
      type: String,
    },
  },

  setup() {
    return {
      info: registerStore.parkVisitInfo,
    };
  },

  computed: {
    visit() {
      return this.currentValue.park_visit;
    },
  },
};
</script>
