import registerStore from "@/stores/Register";
import {statusTypes} from "@/defaults";

export function entryText(item, text, keyName = 'name') {
  return item && item.data && item.data.attributes && item.data.attributes[keyName]
    ? item.data.attributes[keyName]
    : text || '';
}

/**
 *
 * @param {Array} select
 * @param {string} value
 * @returns {*|string}
 */
export function selectValue2Text(select, value) {
  const entry = select.find((item) => item.value === value);
  return entry ? entry.label : '';
}

export function statusText(status) {
  return selectValue2Text(statusTypes, status);
}

export function companyText(item) {
  return item && item.data && item.data.attributes && item.data.attributes.name
    ? item.data.attributes.name
    : '';
}

export function salutationText(gender) {
  return selectValue2Text(registerStore.selectGenders(), gender);
}


export function birthdateText(date) {
  return date ? date.replace(/^(\d*)-(\d*)-(\d*)$/, '$3.$2.$1') : '';
}

export function dateText(value) {
  const date = new Date(value);
  return !isNaN(date) ? date.toLocaleDateString('de-DE') : '';
}

export function booleanText(value) {
  return value === true ? 'ja' : 'nein';
}

export function addressArray(address) {
  if (!address) {
    return ['', '', '', '']
  }

  return [
    address.street || '',
    address.house_number || '',
    address.postcode || '',
    address.place || '',
  ]
}

export function overnightStayRoomMateArray(value, index) {
  return value[index] // 'Zimmerp. x Anrede', 'Zimmerp. x Vorname', 'Zimmerp. x Nachname',
    ? [salutationText(value[index].gender), value[index].firstname, value[index].lastname]
    : ['', '', ''];
}

export function guestRegistrationArray(value) {
  if (!value.with_guest) {
    return [booleanText(value.with_guest), '', '', '', '', ''];
  }

  return [
    booleanText(value.with_guest),
    salutationText(value.guest_gender),
    value.guest_firstname,
    value.guest_lastname,
    birthdateText(value.guest_birthdate),
    booleanText(value.guest_in_double_room),
  ];
}
