<template>
  <InputField v-bind="inputFieldProps" role="group">
    <select
      class="input -select with-free-text"
      :id="inputId"
      placholder=" "
      :disabled="disabled"
      @change="updateInputValue($event.target.value)"
    >
      <option v-if="defaultText !== undefined" v-text="defaultText" value="nil"></option>
      <option
        v-for="{ value, label } in options"
        :key="`input-select-${value}`"
        v-text="label"
        :value="value"
        :selected="currentValue === value"
      ></option>
      <option v-text="inputOtherText" :value="otherValue" :selected="displayOtherInput"></option>
    </select>
    <template v-if="displayOtherInput">
      <label v-if="inputOtherTextLabel" :for="`${inputId}-text`" class="label -text" v-html="inputOtherTextLabel" />
      <input
        type="text"
        class="input -text"
        :id="`${inputId}-text`"
        @input="updateInputOtherValue($event.target.value)"
        :value="inputOtherValue"
      />
    </template>
  </InputField>
</template>

<script>
import ModelInputField from '../../mixins/input/ModelInputField';
import IsInputField from '../../mixins/input/IsInputField';

// TODO bind validations to other input

export default {
  name: 'SelectFeeTextInput',
  mixins: [IsInputField],

  props: {
    options: {
      required: true,
      type: Array,
    },
    defaultValue: {
      default: undefined,
    },
    defaultText: {
      default: undefined,
      type: String,
    },
    cast: {
      default: undefined,
      type: String,
    },
    otherText: {
      default: undefined,
      type: String,
    },
    otherTextLabel: {
      default: undefined,
      type: String,
    },
    otherValue: {
      default: null,
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.currentValue === undefined && this.defaultText === undefined ) {
        this.updateInputValue(this.options[0].value);
      }
    });
  },

  computed: {
    inputOtherText() {
      return this.createInputLabelTranslation('otherText', '_other_text');
    },

    inputOtherTextLabel() {
      return this.createInputLabelTranslation('otherTextLabel', '_other_text_label');
    },

    displayOtherInput() {
      return this.currentValue === this.otherValue;
    },

    inputOtherValue() {
      return this.readNestedValue(`${this.completeName}_text`, this.model);
    },
  },

  methods: {
    updateInputValue(value, name = null) {
      const isOtherValue = value === `${this.otherValue}`;
      let nextTextValue = isOtherValue ? '' : null;
      let newValue = null;

      if (isOtherValue) {
        newValue = this.otherValue;
      } else {
        newValue = value === 'nil' ? this.defaultValue : this.castValue(value);
      }

      ModelInputField.methods.updateInputValue.call(this, newValue, name);
      this.updateInputOtherValue(nextTextValue, name);
    },

    updateInputOtherValue(value, name = null) {
      ModelInputField.methods.updateInputValue.call(this, value, `${name ? name : this.completeName}_text`);
    },

    castValue(value) {
      switch (this.cast) {
        case 'string':
          return '' + value;
        case 'int':
          // eslint-disable-next-line no-case-declarations
          const intVal = parseInt(value);
          return isNaN(intVal) ? null : intVal;
        case 'bool':
          return value === '' ? null : '' + value === '1';
        default:
          return value;
      }
    },
  },
};
</script>
