export function ready(callback) {
  if (document.readyState !== 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
}

export function readCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  return parts.length === 2 ? parts.pop().split(';').shift() : null;
}

export function writeCookie(name, value, expireInSeconds = null) {
  let expires = '';
  const secure = location.protocol === 'https:' ? '; secure' : '';
  if (expireInSeconds) {
    const date = new Date();
    date.setTime(date.getTime() + expireInSeconds * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/${secure}`;
}

export function parentBySelector($element, selector, maxDepth = 10) {
  if (maxDepth <= 0 || $element === null) {
    return null;
  }

  if ($element.matches(selector)) {
    return $element;
  }

  return $element.parentElement
    ? parentBySelector($element.parentElement, selector, maxDepth - 1)
    : null;
}

export const transitionEndEventName = (() => {
  const el = document.createElement('fakeelement');
  const transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
  }

  for (const t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
})();

export const animationEndEventName = (() => {
  const el = document.createElement('fakeelement');
  const animations = {
    'animation': 'animationend',
    'OAnimation': 'oAnimationEnd',
    'MozAnimation': 'animationend',
    'WebkitAnimation': 'webkitAnimationEnd'
  }

  for (const t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
})();
