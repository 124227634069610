<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <RadiobuttonsInput name="overnight_stay" :list="overnightStayEmployeeTypes" :required="true" />

    <div v-if="showAlert" class="alert warning" v-html="info.general" />

    <template v-if="maxRoomMates > 0">
      <RepeatGroup
        name="overnight_stay_room_mates"
        component-name="PersonRepeatItem"
        :min-length="1"
        :max-length="maxRoomMates"
      />
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import registerStore from '@/stores/Register';
import { overnightStayEmployeeTypes } from '@/defaults';
import IsFieldset from '@/mixins/input/IsFieldset';
import RadiobuttonsInput from '@/components/input/Radiobuttons';
import RepeatGroup from "@/components/input/group/RepeatGroup";

export default {
  name: 'OvernightStay',
  components: { RepeatGroup, RadiobuttonsInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'overnight_stay',
      type: String,
    },
  },

  setup() {
    return {
      overnightStayEmployeeTypes,
      info: registerStore.overnightStayInfo.employee,
      showAlert: ref(false),
      maxRoomMates: ref(0),
    };
  },

  created() {
    this.$nextTick(() => {
      this.updateList(this.type);
    })
  },

  computed: {
    type() {
      return this.currentValue?.overnight_stay;
    },
  },

  watch: {
    type(value) {
      this.updateList(value);
    },
  },

  methods: {
    updateList(type) {
      this.showAlert = type && type !== 'no';
      switch (type) {
        case 'shared':
          this.maxRoomMates = 3;
          break;
        case 'double':
          this.maxRoomMates = 1;
          break;
        default:
          this.maxRoomMates = 0;
          break;
      }
    },
  },
};
</script>
