import NestedValues from '../NestedValues';
import { validationsClear } from '../validations';

export default {
  mixins: [NestedValues],
  emits: ['input'],

  props: {
    validationsObjectName: {
      default: 'validations',
      type: String,
    },
    modelName: {
      default: 'model',
      type: String,
    },
    namePrefix: {
      default: '',
      type: String,
    },
    validationPrefix: {
      default: '',
      type: String,
    },
    idPrefix: {
      default: '',
      type: String,
    },
  },

  computed: {
    validations() {
      return this.$parent[this.validationsObjectName] || null;
    },

    validationErrors() {
      return this.validations && this.validationName in this.validations
        ? this.validations[this.validationName]
        : [];
    },

    hasInputModel() {
      return (
        Object.hasOwnProperty.call(this.$attrs, 'modelValue') &&
        Object.hasOwnProperty.call(this.$attrs, 'onUpdate:modelValue')
      );
    },

    model() {
      if (this.hasInputModel) {
        return null;
      }

      return typeof this.$parent.inputModel === 'object'
        ? this.$parent.inputModel
        : this.$parent[this.modelName] || {};
    },

    currentValue() {
      if (this.hasInputModel) {
        return this.$attrs.modelValue;
      }

      return this.readNestedValue(this.completeName, this.model);
    },
  },

  methods: {
    buildPrefixedName(propName, parentVarName, nameVar) {
      let prefix = '';

      if (typeof this.$parent[parentVarName] === 'string' && this.$parent[parentVarName]) {
        prefix = this.$parent[parentVarName];
      } else if (this[propName]) {
        prefix = this[propName];
      }

      if (this[nameVar] === '' || this[nameVar] === null || this[nameVar] === undefined) {
        return prefix;
      }

      return `${prefix}${prefix ? '.' : ''}${this[nameVar]}`;
    },

    updateInputValue(value, name = null) {
      const completeName = name ? name : this.completeName;

      if (this.hasInputModel) {
        this.$emit('input', value, completeName);
        this.$attrs['onUpdate:modelValue'](value);
        return;
      }

      if (this.validations && this.validationName !== '') {
        validationsClear(this.validations, this.validationName);
      }

      this.$emit('input', value, completeName);

      typeof this.$parent.updateInputValue === 'function'
        ? this.$parent.updateInputValue(value, completeName)
        : this.writeNestedValue(completeName, value, this.model);
    },

    buildHtmlId(name) {
      const id = typeof name !== 'string' ? `${name}` : name;
      return id.replace(/[._]/gi, '-');
    },
  },
};
