import {reactive, ref} from "vue";
import ApiError from "@/api/error/ApiError";
import {CsvCreator} from "@/stores/helper/CsvCreator";

function createPagination(options) {
  return {
    page: options.page || 1,
    pageSize: options.pageSize || 50,
    withCount: true,
  };
}

export class RegistrationList {
  /**
   *
   * @param {function} createFilters
   * @param {function} createEntry
   * @param {RegistrationResource} api
   * @param {string[]} populate
   * @param {{populate: string[], headerRowEntry: string[], createEntry: function, filenamePrefix: string}} csv
   */
  constructor({createFilters, createEntry, api, populate, csv}) {
    this.api = api;
    this.populate = populate;
    this.csv = csv;
    this.createFilters = createFilters;
    this.createEntry = createEntry;
    this.loading = ref(false);
    this.entries = reactive([]);
    this.meta = reactive({
      page: 0,
      pageCount: 0,
      pageSize: 0,
      total: 0,
    });
  }

  async find({filter = null, pagination = null} = {}) {
    if (this.loading.value) {
      return;
    }

    this.loading.value = true;
    this.entries.splice(0);
    const query = {populate: this.populate};
    if (filter) {
      query.filters = this.createFilters(filter);
    }
    if (pagination) {
      query.pagination = createPagination(pagination);
    }
    const res = await this.api.setQuery(query).list();

    if (!res.data) {
      throw new ApiError('Got invalid response.');
    }

    res.data.forEach(({attributes}) => {
      this.entries.push(this.createEntry(attributes));
    });

    if (res.meta && res.meta.pagination) {
      this.meta.page = res.meta.pagination.page;
      this.meta.pageCount = res.meta.pagination.pageCount;
      this.meta.pageSize = res.meta.pagination.pageSize;
      this.meta.total = res.meta.pagination.total;
    }

    this.loading.value = false;
  }

  async changeStatus(hash, newStatus) {
    const res = await this.api.changeStatus(hash, newStatus);

    return res.success || false;
  }

  async csvString({filter = null} = {}) {
    const csvCreator = new CsvCreator();
    const rows = [];
    let progress = true;
    let currentPage = 1;

    // add headlines
    rows.push(csvCreator.rowEntry(this.csv.headerRowEntry));

    // call api for all existing pages
    while (progress) {
      const {meta, data} = await this.loadPage(currentPage, filter);
      progress = currentPage < meta.pagination.pageCount;
      currentPage++;
      rows.push(...data.map(({attributes}) => csvCreator.rowEntry(this.csv.createEntry(attributes))));
    }

    return csvCreator.combine(rows);
  }

  async loadPage(page, filter) {
    const query = {populate: [...this.csv.populate]};
    if (filter) {
      query.filters = this.createFilters(filter);
    }
    query.pagination = createPagination({page, pageSize: 50});
    const res = await this.api.setQuery(query).list();

    if (!res.data || !res.meta || !res.meta.pagination) {
      throw new ApiError('Got invalid response.');
    }

    return res;
  }

  /**
   *
   * @returns {string}
   */
  csvFilenamePrefix() {
    return this.csv.filenamePrefix || 'gala';
  }
}
