import ModelInputField from './ModelInputField';

export default {
  mixins: [ModelInputField],

  props: {
    name: {
      required: true,
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
  },

  unmounted() {
    if (this.currentValue !== undefined) {
      this.updateInputValue(undefined);
    }
  },

  computed: {
    completeName() {
      return this.buildPrefixedName('namePrefix', 'inputModelPrefix', 'name');
    },

    inputModelPrefix() {
      return this.completeName;
    },

    validationName() {
      return this.buildPrefixedName('validationPrefix', 'inputValidationPrefix', 'name');
    },

    inputValidationPrefix() {
      return this.validationName;
    },

    inputIdPrefix() {
      return this.buildHtmlId(this.buildPrefixedName('idPrefix', 'inputIdPrefix', 'name'));
    },

    inputLabelTranslation() {
      return typeof this.$parent.inputLabelTranslation === 'string'
        ? `${this.$parent.inputLabelTranslation}.${this.name}`
        : this.name;
    },

    inputLabel() {
      if (this.label) {
        return this.label;
      }

      if (typeof this.inputLabelTranslation === 'string') {
        const translationKey = `${this.inputLabelTranslation}.legend`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },

    inputDescription() {
      if (this.description) {
        return this.description;
      }

      if (typeof this.inputLabelTranslation === 'string') {
        const translationKey = `${this.inputLabelTranslation}.legend_description`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },
  },
};
