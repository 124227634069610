import Api from '@/api/base/Api';

export class Auth extends Api {
  constructor() {
    super('');
  }

  async login(credentials) {
    try {
      return (await window.axios.post(this.buildUri('auth/local'), credentials)).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async logout() {
    // TODO implement
  }

  async authenticate() {
    try {
      return (await window.axios.post(this.buildUri('auth/local'))).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async me() {
    try {
      return (await window.axios.get(this.buildUri('users/me'))).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }
}

const auth = new Auth();

export default auth;
