import ApiError from './ApiError';

export default class ValidationError extends ApiError {
  constructor(response) {
    super(response);

    this.message = response.data.error.message;
    this.validations = this.createValidations(response.data.error.details.errors);
  }

  createValidations(errorDetails) {
    const validations = {};
    if (!errorDetails) {
      return validations;
    }

    errorDetails.forEach(({message, path}) => {
      let name = path.join('.');

      if (name.substring(name.length - 5) === '_text') {
        name = name.substring(0, name.length - 5);
      }

      if (Array.isArray(validations[name])) {
        validations[name].push(message);
      } else {
        validations[name] = [message];
      }
    });

    return validations;
  }
}
