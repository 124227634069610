export default {
  gender: 'Anrede',
  firstname: 'Vorname',
  lastname: 'Nachname',
  personal_number: 'Personalnummer',

  street: 'Straße',
  house_number: 'Hausnummer',
  postcode: 'PLZ',
  place: 'Ort',

  email: 'E-Mail-Adresse',
  phone: 'Telefonnummer',

  validation: {
    required: 'Ist erforderlich.',
    one_of: 'Enthält nicht den richtigen Wert, muss "{value}" ehalten.',
    defined: 'Muss definiert sein.',

    string: {
      length: 'Die länge darf {value} nicht überschreiten.',
      min: 'Muss mindestens {value} Zeichen haben.',
      max: 'Darf maximal {value} Zeichen haben.',
      matches: 'Passt nicht zum vorgegeben Format "{value}"',
      email: 'Keine gültige E-Mail Adresse.',
      url: 'Keine gültige URL.',
      uuid: 'Keine Gültige UUID.',
      trim: 'Muss getrimmt sein.',
      lowercase: 'Alles muss  klein geschrieben sein.',
      uppercase: 'Alles muss groß geschrieben sein.',
      unique: 'Darf nur einmalig verwendet werden.',
    },
    number: {
      min: 'Muss minimum {value} sein.',
      max: 'Darf maximal {value} sein.',
      lessThan: 'Muss kleiner als {value} sein.',
      moreThan: 'Muss größer als {value} sein.',
      positive: 'Muss positiv sein.',
      negative: 'Muss negativ sein.',
      integer: 'Keine Ganzzahl.',
    },
    boolean: {
      isValue: 'boolean.value|${value}',
      accepted: 'Muss ausgewählt sein.',
    },
    array: {
      min: 'Es muss {value} Element existieren.',
      max: 'Es dürfen nicht mehr als {value} Elemente existieren.',
      length: 'Die Länge darf {value} nicht überschrieten.',
    },

    file_size: 'Maximale Dateigröße von {0} MB erreicht.',
  },

  nav: {
    main: {
      start: 'Start',
      register: 'Zur Gala anmelden',

      gala: {
        title: 'Gala',
        eventing: 'Der Gala-Abend',
        faq: 'FAQ',
        participation_conditions: 'Teilnahmebedingungen',
        gallery: 'Bildergalerie',
      },

      cup: {
        title: 'Cup',
        about: 'Über den Cup',
        faq: 'FAQ',
        participation_conditions: 'Teilnahmebedingungen',
      },
    },

    legal_notice: {
      aria: 'Rechtliche Hinweise',

      imprint: 'Impressum',
      data_protection: 'Datenschutz',
    },

    backend: {
      graduate: 'Anmeldungen Absolvent:in',
      employee: 'Anmeldungen Mitarbeiter:in',
    },
  },

  login: {
    password: 'Passwort',
    password_hint:
      'Wir setzen ein Cookie, damit Sie das Passwort nicht jedes Mal angeben müssen, wenn Sie die Website aufrufen. Das Cookie ist 30 Tage aktiv.',
    submit: 'Jetzt einloggen',
    incorrect_password: 'Falsches Passwort*',
  },

  not_found: {
    headline: '404',
    subheadline: 'Leider konnte die Seite nicht gefunden werden.',
    text: 'Doch keine Sorge! Mit klick auf den Button gelangen Sie zurück zum Start.',
    link_text: 'Bring mich zurück',
  },

  register: {
    type: 'Bitte Auswählen',
    // type_hint: 'Unternehmen: Ab Anmeldung für 8 Mitarbeitende',
    type_list: {
      graduate: 'Absolvent:in',
      employee: 'Mitarbeitende',
      company: 'Unternehmen',
    },

    status: {
      registered: 'Eingereicht',
      confirmed: 'Bestätigt',
      canceled: 'Storniert',
    },

    type_selected_btn: 'OKAY',

    graduate: {
      education: 'Mein Abschluss',
      education_other_text: 'andere Berufsausbildung',
      education_other_text_label: 'Berufsausbildung angeben',
      company: 'Bei dieser Firma',

      // Training Company
      person_info_legend:
        'Ich bin Absolvent:in und möchte mich verbindlich zur Abschlussgala anmelden.',

      gender: '@:gender',
      firstname: '@:firstname',
      lastname: '@:lastname',
      personal_number: '@:personal_number',

      address: {
        street: '@:street',
        house_number: '@:house_number',
        postcode: '@:postcode',
        place: '@:place',
      },

      email: '@:email',
      phone: '@:phone',

      cost_info:
        'Die Kosten für den Gala-Abend, sowie die Übernachtung trägt für Sie, als Absolvent:in, die EDEKA Südwest.',

      birthdate: 'Mein Geburtsdatum (Jahr, Monat, Tag)',

      // Training Company
      training_company_legend: 'Mein Ausbildungsbetrieb',

      client_number: 'Kundennummer (nur für Märkte)',
      training_company: 'Ausbildungsbetrieb',

      training_company_address: {
        street: '@:street',
        house_number: '@:house_number',
        postcode: '@:postcode',
        place: '@:place',
      },

      instructor: 'Mein:e Ausbilder:in im Betrieb',
      education_officers: 'Mein:e EDEKA-Bildungsreferent:in (Mehrfachauswahl möglich)',
      seminar_location: 'Mein Seminarstandort',
      seminar_location_other_text: 'Sonstiger',

      intolerances: 'Lebensmittelunverträglichkeiten',

      clothing_size: 'Konfektionsgröße',

      // Grade
      grade_legend: 'Benotung',

      overall_grade: 'Bei der Abschlussprüfung habe ich mit dieser Gesamtnote abgeschlossen',
      overall_grade_description:
        'Die Gesamtnote bei der IHK setzt sich zusammen aus der Summe der Noten der Abschlussprüfung geteilt durch die Anzahl der Prüfungsbereiche. Wenn Sie keine Gesamtnote beim HWK-Zeugnis haben, müssen Sie die Summe der Noten für Fertigkeit und Kenntnisse zusammen zählen und durch zwei teilen.',
      total_score: 'Bei der Abschlussprüfung habe ich diese Gesamtpunktzahl erreicht',

      grade_info:
        'Bitte beachten Sie, dass für die Preisverleihung nur die Abschlussnote (IHK, HWK, DHBW) relevant ist, nicht die Berufsschulbenotung! Damit Sie an der Ehrung sowie an der Verleihung der EDEKA Absolventen-Cups teilnehmen können, benötigen wir zudem zwingend eine Kopie Ihres Kammerzeugnisses! (Email/Fax/Online-Upload)',

      // Overnight stay
      overnight_stay_legend: 'Übernachtung',

      overnight_stay: 'Ich möchte nach der Veranstaltung übernachten',
      overnight_stay_type_list: {
        shared: 'Mehrbettzimmer',
        double: 'Doppelzimmer mit Gast',
        yes: 'Ja',
        no: 'Nein',
      },
      overnight_stay_room_mates: {
        legend_description: 'Ich möchte mit folgenden Absolvent:innen ein Zimmer teilen',

        add: '+',
        remove: '-',

        gender: '@:gender',
        firstname: '@:firstname',
        lastname: '@:lastname',
      },

      // Park visit
      park_visit_legend:
        'Auf Wunsch erhalten Sie mit dieser Vorbestellung am Tag der Gala EINE Eintrittskarte für den Europa-Park, die wir Ihnen zu Ihrem Abschluss schenken und die nur am Folgetag eingelöst werden kann.',

      park_visit: 'Ich möchte am Folgetag den Europa-Park besuchen',
      park_visit_left: 'Nein',
      park_visit_right: 'Ja',

      // Guest
      guest_legend: 'Mein Gast',

      with_guest: 'Ich möchte eine Begleitperson mitbringen',
      with_guest_left: 'Nein',
      with_guest_right: 'Ja',

      guest_gender: '@:gender Gast',
      guest_firstname: '@:firstname Gast',
      guest_lastname: '@:lastname Gast',

      guest_birthdate: 'Geburtsdatum Gast (Jahr, Monat, Tag)',

      guest_in_double_room:
        'Mein Gast übernachtet mit mir im Doppelzimmer. Die Buchung erfolgt über die EDEKA Südwest.',
      guest_in_double_room_left: 'Nein',
      guest_in_double_room_right: 'Ja',
      guest_in_double_room_hint:
        'Es bestehen Zimmerkontingente in den Hotels des Europa-Parks. Die hierfür anfallenden Kosten entnehmen Sie bitte den Teilnahmebedingungen.',

      register_uploads: 'Dateien',
      register_uploads_description:
        'Bitte laden Sie hier Ihr Abschlusszeugnis IHK/HWK/DHWB (kann nachgereicht werden, falls noch nicht vorhanden), ein Foto von Ihnen (gerne eines, das Sie bei der Arbeit zeigt) und – falls sie unter 18 Jahre alt sind – die unterschriebene Einverständniserklärung hoch. Wichtig: Bitte benennen Sie das Foto mit Ihrem Vor- und Nachnamen.',
      register_uploads_btn: 'Datei hochladen',

      data_protection: 'Ich habe die {linkStart}Datenschutzerklärung{linkEnd} gelesen.',
      participation_conditions: 'Ich akzeptiere die {linkStart}Teilnahmebedingungen{linkEnd}.',
      informed_supervisor:
        'Mein:e Vorgesetzte:r ist über die Anmeldung an der Abschlussgala informiert.',
    },

    employee: {
      gender: '@:gender',
      firstname: '@:firstname',
      lastname: '@:lastname',
      email: '@:email',
      phone: '@:phone',
      company: 'Firma',
      cost_info_company_headline: 'Kosteninfo Firma',

      company_legend: 'Firmendaten sowie Einladungsadresse',
      customer_number: 'Kundennummer/Kostenstelle',
      market: 'Markt/Abteilung',
      company_address: {
        street: '@:street',
        house_number: '@:house_number',
        postcode: '@:postcode',
        place: '@:place',
      },
      billing_address_different_right: 'Abweichende Rechnungsadresse',
      billing_address: {
        street: '@:street',
        house_number: '@:house_number',
        postcode: '@:postcode',
        place: '@:place',
      },

      intolerances: 'Lebensmittelunverträglichkeiten',

      overnight_stay_legend: 'Ich möchte nach der Veranstaltung übernachten',
      overnight_stay: '',
      overnight_stay_type_list: {
        single: 'Einzelzimmer',
        double: 'Doppelzimmer',
        shared: 'Mehrbettzimmer',
        no: 'Nein',
      },
      overnight_stay_room_mates: {
        legend: 'Wunschzimmerpartner:in',
        add: '+',
        remove: '-',

        gender: '@:gender',
        firstname: '@:firstname',
        lastname: '@:lastname',
      },

      additional_person_legend: 'Zusätzliche:r Führungskraft/Ausbilder:in/Mitarbeiter:in',
      additional_person_description:
        'Fügen Sie weitere Personen der Anmeldung hinzu, indem Sie den "+"-Button klicken.',
      additional_person: {
        add: '+',
        remove: '-',

        gender: '@:gender',
        firstname: '@:firstname',
        lastname: '@:lastname',

        address_different:
          'Gilt für diese Person eine andere Einladungsadresse/Rechnungsadresse als bei Ihnen?',
        address_different_left: 'Nein',
        address_different_right: 'Ja',
        market: 'Markt/Abteilung',
        address: {
          street: '@:street',
          house_number: '@:house_number',
          postcode: '@:postcode',
          place: '@:place',
        },
        billing_address_different_right: 'Abweichende Rechnungsadresse',
        billing_address: {
          street: '@:street',
          house_number: '@:house_number',
          postcode: '@:postcode',
          place: '@:place',
        },

        overnight_stay_legend:
          'Zusätzliche:r Führungskraft/Ausbilder:in/Mitarbeiter:in möchte nach der Veranstaltung übernachten:',
        overnight_stay: 'Übernachtung',
        overnight_stay_room_mates: {
          add: '+',
          remove: '-',

          gender: '@:gender',
          firstname: '@:firstname',
          lastname: '@:lastname',
        },
      },

      data_protection: 'Ich habe die {linkStart}Datenschutzerklärung{linkEnd} gelesen.',
      participation_conditions: 'Ich akzeptiere die {linkStart}Teilnahmebedingungen{linkEnd}.',
      informed_supervisor:
        'Mein:e Vorgesetzte:r ist über die Anmeldung an der Abschlussgala informiert.',
    },

    submit: 'Absenden',
    cancel: 'Stornieren',
  },

  backend: {
    login: {
      password: 'Passwort',
      submit: 'Jetzt einloggen',
      incorrect_password: 'Falsches Passwort*',
    },

    graduate: {
      education: 'Abschluss',
      company: 'Firma',
      salutation: 'Anrede',
      firstname: 'Vorname',
      lastname: 'Nachname',
      email: 'E-Mail-Adresse',
      birthdate: 'Geburtsdatum',
      park_visit: 'Eintrittskarte Park',
      overnight_stay: 'Übernachtung',
      status: 'Status',
      uploads: 'hochgeladene Dateien',

      btn_edit: 'Bearbeiten',

      filter: {
        name: 'Namen',
        age: 'Alter',
        has_data: 'hat Datein',
        status: 'Status',

        age_select: {
          over_18: 'Über 18',
          under_18: 'Unter 18',
        },

        btn_submit: 'Suchen',
        btn_reset: 'Zurücksetzen',
        btn_export: 'CSV Export',
      },
    },

    employee: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      email: 'E-Mail-Adresse',
      status: 'Status',

      btn_edit: 'Bearbeiten',

      filter: {
        name: 'Namen',
        status: 'Status',

        btn_submit: 'Suchen',
        btn_reset: 'Zurücksetzen',
        btn_export: 'CSV Export',
      },
    },
  },
};
