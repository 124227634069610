import Strapi from '@/api/base/Strapi';

export default class StrapiResource extends Strapi {
  constructor(uri, { allowedQueryKeys = [], documentIdPropertyName = 'id' } = {}) {
    super(uri, { allowedQueryKeys, documentIdPropertyName });
  }

  async list() {
    try {
      return (
        await window.axios.get(this.buildUri(), {
          params: this.buildQuery(),
        })
      ).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async get(id) {
    try {
      return (
        await window.axios.get(this.buildUri(id), {
          params: this.buildQuery(),
        })
      ).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async create(data) {
    let headers = {};

    if (data instanceof FormData) {
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      data = { data };
    }

    try {
      return (await window.axios.post(this.buildUri(), data, { headers })).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async update(id, data) {
    try {
      let headers = {};

      if (data instanceof FormData) {
        headers = { 'Content-Type': 'multipart/form-data' };
      } else {
        data = { data };
      }

      return (await window.axios.put(this.buildUri(id), data, { headers })).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async delete(id) {
    try {
      return (await window.axios.delete(this.buildUri(id))).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }
}
