<template>
  <div class="btn-group center">
    <router-link
      class="btn btn-square bg-yellow"
      :to="{ name: 'backend.graduate' }"
      v-text="$t('nav.backend.graduate')"
    />
    <router-link
      class="btn btn-square bg-yellow"
      :to="{ name: 'backend.employee' }"
      v-text="$t('nav.backend.employee')"
    />
  </div>
</template>

<script setup>

</script>
