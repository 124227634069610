import { reactive, readonly, ref } from 'vue';
import StrapiSingle from '@/api/base/StrapiSingle';
import ApiError from '@/api/error/ApiError';
import { registrationConfig, prepareAlert } from '@/stores/helper/register';
import ValidationError from '@/api/error/ValidationError';
import i18n from '@/i18n';
import { maxFileSizeReached, MAX_FILE_UPLOAD_SIZE } from '@/mixins/input/CheckMaxFileSize';

function createMaxFileSizeValidationError(filesPath) {
  return new ValidationError({
    data: {
      error: {
        message: i18n.global.t('validation.file_size', [MAX_FILE_UPLOAD_SIZE]),
        details: {
          errors: [
            {
              message: i18n.global.t('validation.file_size', [MAX_FILE_UPLOAD_SIZE]),
              path: filesPath,
            },
          ],
        },
      },
    },
  });
}

class Register {
  constructor() {
    this.loaded = ref(false);
    this.eventDate = ref(new Date(process.env.VUE_APP_EVENT_DATE || '2022-10-15'));
    this.selects = reactive({});
    this.info = reactive({});
    this.links = reactive({
      dataProtection: '',
      participationConditions: '',
    })
    this.cancellationInfo = reactive({});
    this.waitingList = reactive({});
    this.unterEighteenInfo = reactive({});
    this.overnightStayInfo = reactive({ graduate: {}, employee: {} });
    this.parkVisitInfo = reactive({});
    this.guestInfo = reactive({});
    this.cancellationAlert = reactive({});
    this.success = reactive({});
  }

  async load() {
    if (this.loaded.value) {
      return;
    }

    const registerApi = new StrapiSingle('register');
    try {
      const response = await registerApi
        .setQuery({
          populate: [
            'waiting_list',
            'cancellation_info',
            'under_eighteen_file',
            'success_create_graduate',
            'success_create_employee',
            'success_update_graduate',
            'success_update_employee',
            'success_canceled_graduate',
            'success_canceled_employee',
          ],
        })
        .get();

      this._fillAttributesData(response.data.attributes);
      // this._fillSelects(response.data.attributes);

      this.loaded.value = true;
      Object.assign(this.selects, response.data.selects);
      Object.assign(this.info, response.data.info);
    } catch (e) {
      // TODO handle error
      console.log(e);
      throw e;
    }
  }

  _fillAttributesData(attributes) {
    Object.assign(this.cancellationInfo, {
      headline: attributes.cancellation_info.headline,
      text: attributes.cancellation_info.text,
      accept: attributes.cancellation_info.accept,
    });

    Object.assign(this.waitingList, {
      active: !!attributes.waiting_list.active,
      text: attributes.waiting_list.text,
    });

    Object.assign(this.unterEighteenInfo, {
      text: attributes.under_eighteen_info,
      buttonText: attributes.under_eighteen_button,
      buttonLink: attributes.under_eighteen_file.data.attributes.url, // TODO generate correct Download link may use env var to prepand hostname
    });

    Object.assign(this.overnightStayInfo, {
      graduate: {
        general: attributes.overnight_stay_graduate_info,
      },
      employee: {
        general: attributes.overnight_stay_employee_info,
      },
    });

    Object.assign(this.parkVisitInfo, {
      text: attributes.park_visit_info,
    });

    Object.assign(this.guestInfo, {
      description: attributes.guest_description,
    });

    Object.assign(this.cancellationAlert, {
      headline: attributes.cancellation_alert_headline,
      text: attributes.cancellation_alert_text,
    });

    Object.assign(this.success, {
      created: {
        graduate: prepareAlert(attributes, 'success_create_graduate'),
        employee: prepareAlert(attributes, 'success_create_employee'),
      },
      updated: {
        graduate: prepareAlert(attributes, 'success_update_graduate'),
        employee: prepareAlert(attributes, 'success_update_employee'),
      },
      canceled: {
        graduate: prepareAlert(attributes, 'success_canceled_graduate'),
        employee: prepareAlert(attributes, 'success_canceled_employee'),
      }
    });

    this.links.dataProtection = attributes.link_data_protection;
    this.links.participationConditions = attributes.link_participation_conditions;
  }

  selectEducationTypes() {
    return readonly(this.selects.educationTypes || []);
  }

  selectEducationOfficers() {
    return readonly(this.selects.educationOfficers || []);
  }

  selectSeminarLocations() {
    return readonly(this.selects.seminarLocations || []);
  }

  selectCompanies() {
    return readonly(this.selects.companies || []);
  }

  selectGenders() {
    return readonly(this.selects.genders || []);
  }

  selectClothingSizes() {
    return readonly(this.selects.clothingSizes || []);
  }

  async loadEntry(type, hash) {
    const { api, populate, prepareData } = registrationConfig(type);

    const response = await api.setQuery({ populate }).get(hash);
    if (!response.data?.attributes) {
      throw new ApiError(response);
    }

    return prepareData(response.data.attributes);
  }

  async _saveEntry(type, data, callApi) {
    const { api, populate, prepareData } = registrationConfig(type);
    const formData = new FormData();

    // make sure hash and status will not be sent
    delete data.hash;
    delete data.status;

    if (data.register_uploads) {
      data.uploads = [];

      if (maxFileSizeReached(data.register_uploads)) {
        throw createMaxFileSizeValidationError(['register_uploads']);
      }

      [...data.register_uploads].forEach((file) => {
        file instanceof File
          ? formData.append(`files.uploads`, file, file.name)
          : data.uploads.push(file);
      });

      delete data.register_uploads;
    }

    formData.append('data', JSON.stringify(data));

    const response = await callApi(api.setQuery({ populate }), formData);
    if (!response.data?.attributes) {
      throw new ApiError(response);
    }

    return prepareData(response.data.attributes);
  }

  async createEntry(type, data) {
    return await this._saveEntry(type, data, async (api, formData) => await api.create(formData));
  }

  async updateEntry(type, hash, data) {
    return await this._saveEntry(
      type,
      data,
      async (api, formData) => await api.update(hash, formData)
    );
  }

  async cancel(type, hash) {
    const { api } = registrationConfig(type);

    return !!(await api.changeStatus(hash, 'canceled')).success;
  }
}

const register = new Register();
export default register;
