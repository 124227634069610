<template>
  <div class="input-field" :class="inputClasses" role="radiogroup">
    <div class="radiobuttons" :class="listClass">
      <div class="item" v-for="{ value, label } in list" :key="`checkbox-${value}`">
        <input
          :id="`${inputId}-${buildHtmlId(value)}`"
          class="input -radio"
          type="radio"
          :disabled="disabled"
          :checked="isChecked(value)"
          @input="inputChanged(value)"
        />
        <label :for="`${inputId}-${buildHtmlId(value)}`" class="text" v-text="label" />
      </div>
    </div>

    <span v-if="inputLabel" class="label" :class="{ legend: hasLegendLabel }" v-html="inputLabel" />
    <span v-if="inputDescription" class="description" v-html="inputDescription" />
    <span v-if="inputHint" class="hint" v-html="inputHint" />

    <span
      class="error"
      v-for="(validation, index) in validationErrors"
      :key="index"
      v-text="validation"
    />
  </div>
</template>

<script>
import ModelInputField from '../../mixins/input/ModelInputField';
import InputFieldProps from '../../mixins/input/InputFieldProps';
import HasComputedLabel from '../../mixins/input/HasComputedLabel';

export default {
  name: 'RadiobuttonsInput',
  mixins: [ModelInputField, InputFieldProps, HasComputedLabel],

  props: {
    list: {
      required: true,
      type: Array,
    },
    cast: {
      default: undefined,
      type: String,
    },
    listClass: {
      type: String,
    },
    hasLegendLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    completeName() {
      return this.buildPrefixedName('namePrefix', 'inputModelPrefix', 'name');
    },

    validationName() {
      return this.buildPrefixedName('validationPrefix', 'inputValidationPrefix', 'name');
    },

    inputId() {
      return this.buildHtmlId(this.buildPrefixedName('idPrefix', 'inputIdPrefix', 'name'));
    },

    inputClasses() {
      return {
        [`-${this.buildHtmlId(this.name)}`]: true,
        '-error': this.validations && this.validationName in this.validations,
        '-required': this.required,
      };
    },
  },

  methods: {
    isChecked(value) {
      const casted = this.castValue(value);
      return this.currentValue === casted;
    },

    inputChanged(value) {
      const casted = this.castValue(value);
      this.updateInputValue(casted);
    },

    castValue(value) {
      switch (this.cast) {
        case 'string':
          return `${value}`;
        case 'int':
          return parseInt(value);
        default:
          return value;
      }
    },
  },
};
</script>
