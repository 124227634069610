<template>
  <nav class="nav-main" ref="nav" data-nav="main">
    <div class="bar">
      <router-link class="logo" :to="{ name: 'home' }">
        <img src="@/assets/core/img/logo_edeka-suedwest_white.png" alt="EDEKA Südwest" />
      </router-link>
      <button
        class="control nav-button"
        data-nav-button=""
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </button>
    </div>

    <div class="menu" role="menubar">
      <router-link
        class="item start"
        :to="{ name: 'home' }"
        role="menuitem"
        tabindex="0"
        v-text="$t('nav.main.start')"
      />

      <NavMainSub :title="$t('nav.main.gala.title')">
        <router-link
          class="item"
          :to="{ path: '/gala/der-abend' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.gala.eventing')"
        />
        <router-link
          class="item"
          :to="{ path: '/gala/faq' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.gala.faq')"
        />
        <router-link
          class="item"
          :to="{ path: '/gala/tnb' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.gala.participation_conditions')"
        />
        <router-link
          class="item"
          :to="{ path: '/gala/galerie' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.gala.gallery')"
        />
      </NavMainSub>

      <NavMainSub :title="$t('nav.main.cup.title')">
        <router-link
          class="item"
          :to="{ path: '/cup/ueber-den-cup' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.cup.about')"
        />
        <router-link
          class="item"
          :to="{ path: '/cup/faq' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.cup.faq')"
        />
        <router-link
          class="item"
          :to="{ path: '/cup/tnb' }"
          role="menuitem"
          tabindex="-1"
          v-text="$t('nav.main.cup.participation_conditions')"
        />
      </NavMainSub>

      <hr class="separator" />
      <router-link
        class="teaser-link btn btn-round bg-yellow icon-after -external-link"
        :to="{ name: 'register' }"
        v-text="$t('nav.main.register')"
      />
    </div>

    <router-link
      v-if="registrationEnabled"
      class="teaser-link btn btn-round bg-yellow icon-after -external-link"
      :to="{ name: 'register' }"
      v-text="$t('nav.main.register')"
    />
  </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { NavMain } from '@/assets/core/js/nav-main';
import NavMainSub from '@/components/nav/NavMainSub';

const router = useRouter();
const registrationEnabled = process.env.VUE_APP_REGISTRATION_ENABLED !== 'false';
const nav = ref(null);
const navMain = new NavMain();

// on navigation always close the menu if opened
router.afterEach(() => {
  if (navMain.$navButton && navMain.opened) {
    navMain.navClose(navMain.$navButton);
  } else {
    navMain.closeAllSubMenus();
  }
});

onMounted(() => {
  navMain.init(nav.value);
});

// TODO onUnmounted vue action need to destroy the menu instance (it should deregister all it's listeners);
</script>
