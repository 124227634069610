export function readNestedValue(name, object) {
  const index = name.indexOf('.');

  if (object === undefined || object === null) {
    return undefined;
  }

  if (name === '') {
    return object;
  }

  if (index <= 0) {
    return object[name];
  }

  const currentName = name.substring(0, index);
  const nextName = name.substring(index + 1);

  if (!object[currentName]) {
    return undefined;
  }

  return readNestedValue(nextName, object[currentName]);
}

export function writeNestedValue(name, value, object) {
  const newObject = object;
  const index = name.indexOf('.');

  if (index <= 0) {
    if (value === undefined) {
      delete newObject[name];
    } else {
      newObject[name] = value;
    }
    return newObject;
  }

  const currentName = name.substring(0, index);
  const nextName = name.substring(index + 1);

  if (
    !newObject[currentName] ||
    (Array.isArray(newObject[currentName]) && newObject[currentName].length <= 0)
  ) {
    newObject[currentName] = {};
  }

  newObject[currentName] = writeNestedValue(nextName, value, newObject[currentName]);
  return newObject;
}

export default {
  methods: {
    readNestedValue,
    writeNestedValue,
  },
};
