<template>
  <InputField v-bind="inputFieldProps">
    <span class="input -switch">
      <span v-if="inputLabelLeft" class="label-left" v-text="inputLabelLeft" />
      <input
        :id="inputId"
        class="checkbox"
        type="checkbox"
        :disabled="disabled"
        :checked="currentValue"
        @input="updateInputValue($event.target.checked)"
      />
      <span class="slide" />
      <span v-if="inputLabelRight" class="label-right" v-text="inputLabelRight" />
    </span>
  </InputField>
</template>

<script>
import IsCheckboxField from '../../mixins/input/IsCheckboxField';

export default {
  name: 'ToggleSwitch',

  mixins: [IsCheckboxField],

  props: {
    labelLeft: {
      default: null,
      type: String,
    },
    labelRight: {
      default: null,
      type: String,
    },
  },

  computed: {
    inputLabelLeft() {
      if (this.labelLeft) {
        return this.labelLeft;
      }

      if (this.inputLabelTranslationKey) {
        const translationKey = `${this.inputLabelTranslationKey}_left`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },

    inputLabelRight() {
      if (this.labelRight) {
        return this.labelRight;
      }

      if (this.inputLabelTranslationKey) {
        const translationKey = `${this.inputLabelTranslationKey}_right`;
        return this.$te(translationKey) ? this.$t(translationKey) : null;
      }

      return null;
    },
  },
};
</script>
