<template>
  <header class="header">
    <NavMain v-if="isLoggedIn" />
  </header>
  <main class="main" :class="mainClass">
    <router-view />
  </main>

  <footer class="footer">
    <NavLegalNotices />
  </footer>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { isLoggedIn } from '@/stores/CurrentUser';
import NavMain from '@/components/nav/NavMain';
import NavLegalNotices from '@/components/nav/NavLegalNotices';

const route = useRoute();
const routeClassNameMap = {
  'backend.home': 'backend-table',
  'backend.employee': 'backend-table',
  'backend.graduate': 'backend-table',
};

watch(isLoggedIn, (value) => {
  const app = document.querySelector('body > .app');

  if (value) {
    app.classList.add('with-nav-main');
  } else {
    app.classList.remove('with-nav-main');
  }
});

const mainClass = computed(() => {
  const className = [];

  if (route && route.name) {
    className.push(
      Object.hasOwnProperty.call(routeClassNameMap, route.name)
        ? routeClassNameMap[route.name]
        : route.name.replaceAll('.', '-')
    );
  }

  if (route && route.name && route.name.substring(0, 8) !== 'backend.') {
    className.push('container', '-content');
  }

  return className;
});
</script>

<style lang="less">
@import './less/app';
</style>
