import Api from '@/api/base/Api';

export default class Strapi extends Api {
  constructor(uri, { allowedQueryKeys = [], documentIdPropertyName = 'id' } = {}) {
    // build query keys unique array
    const queryKeys = [
      ...allowedQueryKeys,
      'sort',
      'filters',
      'populate',
      'fields',
      'pagination',
      'publicationState',
      'locale',
    ].filter((v, i, a) => a.indexOf(v) === i);
    super(uri, { allowedQueryKeys: queryKeys });

    this.documentIdPropertyName = documentIdPropertyName;
  }
}
