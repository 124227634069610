<template>
  <article v-if="!loading" class="article">
    <header class="header">
      <h1 class="headline" v-text="page.title" />
      <span v-if="page.subtitle" class="subheadline" v-text="page.subtitle" />
    </header>

    <component
      v-for="(block, index) in page.content"
      :key="`content-block-${index}`"
      :is="block.component"
      v-bind="block.data"
    />
  </article>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import pages from '@/stores/Pages';
import NotFoundError from '@/api/error/NotFoundError';

// TODO refactor this to load different single page types.

const route = useRoute();
const router = useRouter();
const loading = ref(true);
const page = pages.dataProtection;

pages
  .loadDataProtection()
  .then(() => {
    loading.value = false;
  })
  .catch((error) => {
    if (error instanceof NotFoundError) {
      router.push({
        name: 'not-found',
        // preserve current path and remove the first char to avoid the target URL starting with `//`
        params: { pathMatch: route.path.substring(1).split('/') },
        // preserve existing query and hash if any
        query: route.query,
        hash: route.hash,
      });
      return;
    }

    throw error;
  });
</script>
