<template>
  <div class="alert warning">
    <p v-text="underEighteenInfo.text" />

    <div class="btn-group">
      <a
        class="btn btn-square bg-alert"
        :href="underEighteenInfo.buttonLink"
        download=""
        v-text="underEighteenInfo.buttonText"
      />
    </div>
  </div>
</template>

<script setup>
import registerStore from '@/stores/Register';

const underEighteenInfo = registerStore.unterEighteenInfo;
</script>
