<template>
  <div role="group" class="fieldset repeat" :class="{ 'without-add': !canAddItems }">
    <span v-if="inputLabel" class="legend" v-text="inputLabel" />
    <div v-if="inputDescription" class="description" v-html="inputDescription" />

    <template v-if="currentValue">
      <component
        v-for="(model, index) in currentValue"
        :is="componentName"
        :index="index"
        :key="`${name}-${index}`"
        :class="['item', 'fieldset', name]"
      >
        <button
          :disabled="index < minLength"
          type="button"
          class="btn btn-square no-arrow bg-yellow remove"
          v-text="$t(`${inputLabelTranslation}.remove`)"
          @click.prevent="removeItem(index)"
        />
      </component>
    </template>

    <button
      v-if="canAddItems"
      type="button"
      class="btn btn-square no-arrow bg-yellow add"
      v-text="$t(`${inputLabelTranslation}.add`)"
      @click.prevent="addItem"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import IsRepeatGroup from '@/mixins/input/IsRepeatGroup';

export default {
  name: 'RepeatGroup',

  mixins: [IsRepeatGroup],

  props: {
    componentName: {
      required: true,
      type: String,
    },
    minLength: {
      default: 0,
      type: Number,
    },
    maxLength: {
      default: -1,
      type: Number,
    },
  },

  created() {
    this.$nextTick(() => {
      if (!Array.isArray(this.currentValue)) {
        if (this.minLength <= 0) {
          this.updateInputValue([]);
        } else {
          this.updateInputValue(Array(this.minLength).fill({}));
        }
      }
    });
  },

  mounted() {
    this.checkAddItems();
  },

  setup() {
    return {
      canAddItems: ref(false),
    };
  },

  watch: {
    minLength(value) {
      if (value > this.currentValue.length) {
        const items = [...this.currentValue, ...Array(value - this.currentValue.length).fill({})];
        this.updateInputValue(items);
      }

      this.checkAddItems();
    },
    maxLength(value) {
      if (value < this.currentValue.length) {
        const items = this.currentValue.splice(0, value);
        this.updateInputValue(items);
      }

      this.checkAddItems();
    },
    currentValue() {
      this.checkAddItems();
    },
  },

  methods: {
    checkAddItems() {
      this.canAddItems =
        this.currentValue && (this.maxLength === -1 || this.currentValue.length < this.maxLength);
    },

    addItem(e) {
      if (!this.canAddItems) {
        return;
      }

      // leave focus of element
      if (e.target && e.target === document.activeElement) {
        document.activeElement.blur();
      }
      const items = this.currentValue ? [...this.currentValue] : [];
      items.push({});
      this.updateInputValue(items);
    },

    removeItem(index) {
      if (index < this.minLength) {
        return;
      }

      const items = [...this.currentValue];
      items.splice(index, 1);
      this.updateInputValue(items);
    },
  },
};
</script>
