import Strapi from '@/api/base/Strapi';

export default class StrapiSingle extends Strapi {
  constructor(uri, { allowedQueryKeys = [], documentIdPropertyName = 'id' } = {}) {
    super(uri, { allowedQueryKeys, documentIdPropertyName });
  }

  async get() {
    try {
      return (
        await window.axios.get(this.buildUri(), {
          params: this.buildQuery(),
        })
      ).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async update(data) {
    try {
      return (await window.axios.put(this.buildUri(), { data })).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }

  async delete() {
    try {
      return (await window.axios.delete(this.buildUri())).data;
    } catch (e) {
      this.handleError(e);
      return undefined;
    } finally {
      this.reset();
    }
  }
}
