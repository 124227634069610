<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <SelectInput name="gender" :options="genders" :required="true" default-text="" />
    <BaseInput name="firstname" :required="true" />
    <BaseInput name="lastname" :required="true" />
    <BaseInput v-if="hasPersonalNumber" name="personal_number" />

    <AddressInput name="address" />

    <BaseInput name="email" :required="true" />
    <BaseInput name="phone" :required="true" />

    <div class="alert info">
      <p v-text="$t(`${inputLabelTranslation}.cost_info`)"></p>
    </div>

    <DateSelect name="birthdate" :year-end="2022" :required="true" />
    <UnderEighteenAlert v-if="underEighteen" />
  </div>
</template>

<script>
import registerStore from '@/stores/Register';
import IsFieldset from '@/mixins/input/IsFieldset';
import UnderEighteenCheck from '@/mixins/UnderEighteenCheck';
import SelectInput from '@/components/input/Select';
import BaseInput from '@/components/input/Input';
import DateSelect from '@/components/input/DateSelect';
import AddressInput from '@/components/input/group/Address';
import UnderEighteenAlert from '@/components/alert/UnterEighteen';

export default {
  name: 'PersonInfo',
  components: { UnderEighteenAlert, AddressInput, DateSelect, BaseInput, SelectInput },
  mixins: [IsFieldset, UnderEighteenCheck],
  props: {
    labelTrans: {
      default: 'person_info',
      type: String,
    },
    hasPersonalNumber: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    return {
      genders: registerStore.selectGenders(),
    };
  },

  computed: {
    birthdate() {
      return this.currentValue.birthdate;
    },
  },
};
</script>
