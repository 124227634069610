import ApiError from './ApiError';

export default class AccessError extends ApiError {
  constructor(response) {
    super(response);
    const url = this.urlNoParams();

    switch (response.status) {
      case 401:
        this.message = `Unauthorized for ${url}`;
        break;
      case 403:
        this.message = `Forbidden for ${url}`;
        break;
      default:
        this.message = `No access for ${url}`;
        break;
    }
  }
}
