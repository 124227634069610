export default class ApiError {
  constructor(response) {
    this.message = response.statusText || null;
    this.status = response.status || null;
    this.url = response.request && response.request.responseURL ? response.request.responseURL : '';
  }

  urlNoParams() {
    const queryStringPos = this.url.indexOf('?');

    if (queryStringPos > 0) {
      return this.url.substring(0, queryStringPos);
    }

    return this.url;
  }
}
