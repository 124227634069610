<template>
  <div class="input-field" :class="{ '-with-label-placeholder': withLabelPlaceholder }">
    <slot />
    <slot name="label">
      <label :for="inputId" class="label" v-html="label" />
    </slot>
    <slot name="after-label" />
    <span v-if="description" class="description" v-html="description" />
    <span v-if="hint" class="hint" v-html="hint" />
    <span
      class="error"
      v-for="(validation, index) in $parent.validationErrors"
      :key="index"
      v-text="validation"
    />
  </div>
</template>

<script>
import InputFieldProps from '../../mixins/input/InputFieldProps';

export default {
  name: 'InputField',
  mixins: [InputFieldProps],

  props: {
    inputId: {
      required: true,
      type: String,
    },
  },
};
</script>
