export default class ApiUrlQuery extends URLSearchParams {
  append(name, value) {
    if (typeof value !== 'object' || value === null) {
      super.append(name, value);
      return;
    }

    if (Array.isArray(value)) {
      this.appendArray(name, value);
      return;
    }

    this.appendObject(name, value);
  }

  appendArray(name, value) {
    value.forEach((content, index) => {
      this.append(`${name}[${index}]`, content);
    });
  }

  appendObject(name, value) {
    Object.keys(value).forEach((key) => {
      this.append(`${name}[${key}]`, value[key]);
    });
  }
}
