import registerStore from '@/stores/Register';
import {prepareData as prepareBaseData} from "@/stores/helper/register";
import {
  addressArray,
  birthdateText,
  booleanText,
  companyText,
  dateText,
  entryText,
  guestRegistrationArray,
  overnightStayRoomMateArray,
  salutationText,
  statusText
} from "@/stores/helper/entry-filed-generation";

export const populate = [
  'education',
  'company',
  'address',
  'training_company_address',
  'education_officers',
  'seminar_location',
  'overnight_stay_room_mates',
  'uploads',
];

/**
 *
 * @param {Date} birthdate
 * @param {Date|null} date
 * @returns {number}
 */
export function calculateAge(birthdate, date = null) {
  const today = date || new Date();

  let age = today.getFullYear() - birthdate.getFullYear();
  const m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }
  return age;
}

export function overnightStayText(value) {
  return value === 'no' ? 'nein' : 'ja';
}

export function educationOfficers(value) {
  return value && value.data && value.data.length > 0
    ? value.data.map(({attributes}) => attributes.name)
    : [];
}

export function educationText(item, text) {
  return entryText(item, text, 'name');
}

export function seminarLocationText(item, text) {
  return entryText(item, text, 'name');
}

export function uploads(value) {
  const uploads = [];

  if (value && value.data && value.data.length > 0) {
    value.data.forEach(({attributes}) => {
      const {name, url} = attributes;
      if (name && url) {
        uploads.push({name, url: `${process.env.VUE_APP_STRAPI_API_URL}${url}`});
      }
    });
  }

  return uploads;
}

export function prepareData(data) {
  return prepareBaseData(data);
}

export function listEntry(attributes) {
  return {
    hash: attributes.hash,
    status: attributes.status,
    education: educationText(attributes.education, attributes.education_text),
    company: companyText(attributes.company),
    salutation: salutationText(attributes.gender),
    firstname: attributes.firstname,
    lastname: attributes.lastname,
    email: attributes.email,
    birthdate: birthdateText(attributes.birthdate),
    park_visit: booleanText(attributes.park_visit),
    overnight_stay: overnightStayText(attributes.overnight_stay),
    uploads: uploads(attributes.uploads),
  };
}

export const csvHeaders = [
  'E-Mail-Adresse',
  'Typ',
  'Status',
  'Abschluss',
  'Firma',
  'Anrede',
  'Vorname',
  'Nachname',
  //'Straße',
  //'Hausnummer',
  //'PLZ',
  //'Ort',
  'Telefonnummer',
  'Geburtsdatum',
  'Minderjährig',
  'Kundennummer',
  'Ausbildungsbetrieb',
  'Straße Betrieb',
  'Hausnummer Betrieb',
  'PLZ Berieb',
  'Ort Betrieb',
  'Ausbilder:in',
  'Bildunsreferent:in',
  'Seminarstandort',
  'Note',
  'Punktzahl',
  'Eintrittskarte',
  'Lebensmitteluunverträglichkeiten',
  'Kleidergröße',
  'Übernachtung',
  'Mit Gast',
  'Gast Anrede',
  'Gast Vorname',
  'Gast Nachname',
  'Gast Geburtstag',
  'Gast in Doppelzimmer',
  'Zimmerp. 1 Anrede',
  'Zimmerp. 1 Vorname',
  'Zimmerp. 1 Nachname',
  'Zimmerp. 2 Anrede',
  'Zimmerp. 2 Vorname',
  'Zimmerp. 2 Nachname',
  'Zimmerp. 3 Anrede',
  'Zimmerp. 3 Vorname',
  'Zimmerp. 3 Nachname',
  'Anmeldedatum',
  'Datei enthalten',
  'Links zu Dateien',
];

/**
 * Generate an entry for the csv the number and order of array items need to match the csvHeaders array
 * @param entry
 * @returns {*[]}
 */
export function csvEntry(entry) {
  const files = uploads(entry.uploads);

  return [
    entry.email, //'E-Mail-Adresse',
    'Absolvent:in', //'Type',
    statusText(entry.status), //'Status',
    educationText(entry.education, entry.education_text), //'Abschluss',
    companyText(entry.company), //'Firma',
    salutationText(entry.gender), //'Anrede',
    entry.firstname, //'Vorname',
    entry.lastname, //'Nachname',
    //...addressArray(entry.address), //'Straße', 'Hausnummer', 'PLZ', 'Ort',
    entry.phone, //'Telefonnummer',
    birthdateText(entry.birthdate), //'Geburtsdatum',
    booleanText(calculateAge(new Date(entry.birthdate), registerStore.eventDate.value) < 18), // 'Minderjährig',
    entry.client_number, //'Kundennummer',
    entry.training_company, //'Ausbildungsbetrieb',
    ...addressArray(entry.training_company_address), //'Straße Betrieb', 'Hausnummer Betrieb', 'PLZ Berieb', 'Ort Betrieb',
    entry.instructor, //'Ausbilder:in',
    educationOfficers(entry.education_officers).join('\n'), //'Bildunsreferent:in',
    seminarLocationText(entry.seminar_location, entry.seminar_location_text), //'Seminarstandort',
    entry.overall_grade, //'Note',
    entry.total_score, //'Punktzahl',
    booleanText(entry.park_visit), //'Eintrittskarte',
    entry.intolerances, // 'Lebensmitteluunverträglichkeiten',
    entry.clothing_size, // 'Kleidergröße',
    overnightStayText(entry.overnight_stay), //'Übernachtung',
    ...guestRegistrationArray(entry), // 'Mit Gast', 'Gast Anrede', 'Gast Vorname', 'Gast Nachname', 'Gast Geburtstag', 'Gast in Doppelzimmer',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 0), //'Zimmerp. 1 Anrede', 'Zimmerp. 1 Vorname', 'Zimmerp. 1 Nachname',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 1), //'Zimmerp. 2 Anrede', 'Zimmerp. 2 Vorname', 'Zimmerp. 2 Nachname',
    ...overnightStayRoomMateArray(entry.overnight_stay_room_mates, 2), //'Zimmerp. 3 Anrede', 'Zimmerp. 3 Vorname', 'Zimmerp. 3 Nachname',
    dateText(entry.createdAt), //'Anmeldedatum',
    booleanText(files.length > 0), //'Datei enthalten',
    files.map((file) => file.url).join('\n'), //'Links zu Dateien',
  ];
}
