<template>
  <InputField v-bind="inputFieldProps">
    <template v-slot:label><i v-show="false" /></template>
    <input
      :id="inputId"
      class="input -checkbox"
      type="checkbox"
      :disabled="disabled"
      :checked="currentValue"
      @input="updateInputValue($event.target.checked)"
    />
    <label :for="inputId" class="text" v-html="inputLabel" />
  </InputField>
</template>

<script>
import IsCheckboxField from '../../mixins/input/IsCheckboxField';

export default {
  name: 'CheckboxInput',

  mixins: [IsCheckboxField],
};
</script>
