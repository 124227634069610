import { reactive } from 'vue';
import pageApi from '@/api/page';
import singlePage from '@/api/singlePage';

function preparePageData(page) {
  const data = { ...page.attributes };

  const content = [];
  if (data.content && Array.isArray(data.content)) {
    data.content.forEach((entry) => {
      const blockData = { ...entry };
      delete blockData.__component;
      delete blockData.id;

      content.push({
        component: entry.__component,
        id: entry.id,
        data: blockData,
      });
    });
  }

  data.content = content;

  return data;
}

class Pages {
  constructor() {
    this.pages = reactive({});
    this.home = reactive({});
    this.dataProtection = reactive({});
  }

  async fromSlug(slug) {
    if (!this.isCached(slug)) {
      const page = await pageApi.findBySlug(slug);
      Object.assign(this.pages, {
        [slug]: preparePageData(page),
      });
    }

    return this.pages[slug];
  }

  async loadHome() {
    if (Object.keys(this.home).length === 0) {
      const result = await singlePage
        .setQuery({
          populate: ['header_img'],
        })
        .withContact()
        .withContent()
        .get('home');
      Object.assign(this.home, preparePageData(result.data));
    }

    return true;
  }

  async loadDataProtection() {
    if (Object.keys(this.dataProtection).length === 0) {
      const result = await singlePage
        .withContent()
        .get('data-protection');
      Object.assign(this.dataProtection, preparePageData(result.data));
    }

    return true;
  }

  isCached(slug) {
    return Object.hasOwnProperty.call(this.pages, slug);
  }
}

const pages = new Pages();
export default pages;
