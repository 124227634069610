<template>
  <div :class="groupClass">
    <span v-if="groupLegend" class="legend" v-html="groupLegend" />
    <div v-if="groupDescription" class="description" v-html="groupDescription" />

    <BaseInput name="overall_grade" />
    <NumberInput name="total_score" cast="int" :min="0" :max="999" :step="1" />

    <div class="alert info">
      <p v-text="$t(`${inputLabelTranslation}.grade_info`)"></p>
    </div>
  </div>
</template>

<script>
import IsFieldset from '@/mixins/input/IsFieldset';
import BaseInput from '@/components/input/Input';
import NumberInput from '@/components/input/Number';

export default {
  name: 'GradeInfo',
  components: { NumberInput, BaseInput },
  mixins: [IsFieldset],
  props: {
    labelTrans: {
      default: 'grade',
      type: String,
    },
  },
};
</script>
