import HasComputedLabel from './HasComputedLabel';
import InputField from '../../components/input/InputField';
import InputFieldProps from './InputFieldProps';
import ModelInputField from './ModelInputField';

export default {
  mixins: [ModelInputField, InputFieldProps, HasComputedLabel],
  components: { InputField },

  unmounted() {
    if (this.currentValue !== undefined) {
      this.updateInputValue(undefined);
    }
  },

  computed: {
    completeName() {
      return this.buildPrefixedName('namePrefix', 'inputModelPrefix', 'name');
    },

    validationName() {
      return this.buildPrefixedName('validationPrefix', 'inputValidationPrefix', 'name');
    },

    inputId() {
      return this.buildHtmlId(this.buildPrefixedName('idPrefix', 'inputIdPrefix', 'name'));
    },

    inputFieldProps() {
      return {
        inputId: this.inputId,
        name: this.name,
        label: this.inputLabel,
        description: this.inputDescription,
        hint: this.inputHint,
        disabled: this.disabled,
        required: this.required,
        class: this.inputFieldClasses,
        withLabelPlaceholder: this.withLabelPlaceholder,
      };
    },

    inputFieldClasses() {
      return {
        [`-${this.buildHtmlId(this.name)}`]: true,
        '-error': this.validations && this.validationName in this.validations,
        '-no-label': !this.inputLabel,
        '-required': this.required,
      };
    },
  },
};
